import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';

const TagPopup = ({ isOpen, onClose, onSelect, onDone, selectedItems }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${params.baseURL}${params.endpoints.baseData}`, {}, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.status === 'found') {
          setData(response.data.tags);
          setFilteredData(response.data.tags);
        } else {
          setError(response.message || 'Error in fetching data.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error in fetching data.');
      }
    };
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  useEffect(() => {
    setFilteredData(
      data.filter(item =>
        item.tag_name && item.tag_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, data]);

  const isItemSelected = item => {
    if (!selectedItems || selectedItems.length === 0) return false;
    return selectedItems.some(selected => selected.tag_id === item.tag_id);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="custom-model-main">
      <div className="custom-model-inner">
        <div className='out-search-box'>
          <span className="sc-btn" onClick={onClose}>
            &times;
          </span>
          <input
            type="text"
            className="form-control"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        {error && <p>Error: {error}</p>}
        <div className="api-data-list">
          {filteredData.map(item => (
            <div className='adlb' key={item.tag_id}>
              <input
                type="checkbox"
                id={`${item.tag_id}_tag`}
                name='tags'
                value={item.tag_id}
                checked={isItemSelected(item)}
                onChange={() => onSelect(item)}
              />
              <label htmlFor={`${item.tag_id}_tag`}>{item.tag_name}</label>
            </div>
          ))}
        </div>
        <div className='sctcb-box'>
          <button type="button" className="done-btn" onClick={onDone}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default TagPopup;
