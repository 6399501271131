import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import StackGrid from "react-stack-grid";
import Loading from '../components/Loading';

const VideoWithCustomPlayButton = ({ videoSrc }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
  
    const handlePlayPause = () => {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };
  
    return (
      <>
        <video
          className='lp-img'
          ref={videoRef}
          src={videoSrc}
          style={{ width: '100%', height: 'auto' }}
          onClick={handlePlayPause}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          controls={false}
        />
        {!isPlaying && (
          <button
            onClick={handlePlayPause}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'rgba(0, 0, 0, 1)',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              fontSize: '20px',
              cursor: 'pointer'
            }}
          >
            <img src={'/assets/img/icons/play_btn.svg'} alt='Fyre Search' className='play-btn w-100' />
          </button>
        )}
      </>
    );
};

function Tagged() {

    const [taggedPhotos, setTaggedPhotos] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [loader, setLoader] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isStatusPopup, setIsStatusPopup] = useState(false);
    const [selectedPhotoId, setSelectedPhotoId] = useState(null);
    const [isRewardPopup, setIsRewardPopup] = useState(false);
    const [ownerInfo, setOwnerInfo] = useState([]);

    const errorRef = useRef(null);
    const successMessageRef = useRef(null);
    const gridRef = useRef();

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    useEffect(() => {
        if ((error && errorRef.current) || (successMessage && successMessageRef.current)) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error, successMessage]);

    useEffect(() => {

        const fetchTaggedPhotos = async () => {
          if (businessListingId && token) {
            const formData = new FormData();
            formData.append('listing_id', businessListingId);
            formData.append('token', token);
            formData.append('listing_type', 'listing');
    
            setLoader(true);
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.taggedPhotos}`,
                data: formData
              });
              if (response.data.items && Array.isArray(response.data.items)) {
                setTaggedPhotos(response.data.items);
                setTimeout(() => {
                  if (gridRef.current) {
                    gridRef.current.updateLayout();
                  }
                }, 1000);
              }
              else {
                console.log('Tagged Photos Not Found');
              }
            } catch (error) {
              console.log(error.message);
            } finally {
              setLoader(false);
            }
          }
        };
    
        fetchTaggedPhotos();
    }, [businessListingId, token]);

    const handleReward = (photoId, ownerInfo) => {
        setIsRewardPopup(true);
        setSelectedPhotoId(photoId);
        setOwnerInfo(ownerInfo);
    }

    const giveReward = async (coins) => {
        if(token) {
            const list = new FormData();
            list.append('token', token);
            list.append('photo_id', selectedPhotoId);
            list.append('points', coins);
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.rewardPoint}`,
                    data: list
                  });
                if (response.data.status === 'ok') {
                    setSuccessMessage('Reward given successfully');
                    setIsRewardPopup(false);
                    window.location.reload();
                } else if (response.data.status === 'error') {
                    setIsRewardPopup(false);
                    setError(response.data.list);
                } else {
                    setIsRewardPopup(false);
                    setError('Something went wrong');
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleStatus = async (photoId) => {
        if(token) {
            const list = new FormData();
            list.append('token', token);
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.rejectionOptions}`,
                    data: list
                  });
                  if (response.data.status === 'found') {
                    setReasons(response.data.rejection_options)
                    setIsStatusPopup(true);
                    setSelectedPhotoId(photoId);
                  }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const changeStatus = async (id) => {
        if(token) {
            const list = new FormData();
            list.append('token', token);
            list.append('photo_id', selectedPhotoId);
            list.append('reason_id', id);
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.rejectPhoto}`,
                    data: list
                  });
                if (response.data.status === 'ok') {
                    setIsStatusPopup(false);
                    window.location.reload();
                } else if (response.data.status === 'error') {
                    setIsStatusPopup(false);
                    setError(response.data.list);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <div className="tagged">
          {successMessage && <div className="alert alert-success mb-4" ref={successMessageRef}>{successMessage}</div>}
          {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 

          <div className='lpbs'>
            {loader ? (
              <Loading />
            ) : taggedPhotos.length > 0 ? (
              <>
              <h4 className='lp-title'>Tagged photos/videos for reviews ({taggedPhotos.length})</h4>
              <StackGrid
                className={'photo_grid_view'}
                columnWidth={300}
                gutterWidth={16}
                gutterHeight={16}
                monitorImagesLoaded={true}
              >
                {taggedPhotos.map(item => (
                  <div className='lpib' key={item.photo_id}>               
                    <div className='lpib-action-overlay' style={{width:'72px'}}>                    
                      <button className='lpib-edit-btn'
                        onClick={() => handleReward(item.photo_id, item.owner_info)}
                      >
                        <i class="fas fa-coins"></i>
                      </button>
                      <button className='lpib-edit-btn'
                        onClick={() => handleStatus(item.photo_id)}
                      >
                        <i class="fas fa-bars"></i>
                      </button>
                    </div>
                    {item.media_type === "P" ? (
                        <img 
                          className='lp-img' 
                          src={item.photo_image_url} 
                          alt={item.photo_caption} 
                          onLoad={() => {
                            if (gridRef.current) {
                              gridRef.current.updateLayout();
                            }
                          }}
                        />
                      ) : item.media_type === "V" ? (
                        <VideoWithCustomPlayButton 
                          videoSrc={item.photo_image_url} 
                          onLoad={() => {
                            if (gridRef.current) {
                              gridRef.current.updateLayout();
                            }
                          }}
                        />
                      ) : null}
                    <div className='lpib-overlay'>
                      <div className='lpib-overlay-img'>
                        <img src={item.owner_info.image} alt={item.owner_info.title} />
                      </div>
                      <div className='lpib-overlay-text'>
                        <p>{item.created_time_as_text}</p>
                        <p>{item.owner_info.title}</p>
                        <p style={{marginTop:'10px'}}>{item.formatted_likes} likes</p>
                      </div>
                    </div>
                  </div>
                ))}
              </StackGrid>
              </>
            ) : (
                <div>
                  <h4 className='lp-title'>Tagged photos/videos for reviews ({taggedPhotos.length})</h4>
                  <div className='empty-section'>
                    <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                    <p>No Tagged Photos</p>
                  </div>
                </div>
            )}
          </div>

          {isStatusPopup && (
            <div className='modal-overlay'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4>Choose Reason</h4> 
                        <span className='popup-close' onClick={() => setIsStatusPopup(false)}>&times;</span>  
                    </div>
                    <div className='modal-body'>
                        {reasons.map(option => (
                            <div className='tpvrb' key={option.id}>
                                <input type='radio' id={`option-${option.id}`} name='rejectionOption' value={option.id} />
                                <label htmlFor={`option-${option.id}`}>{option.title}</label>
                            </div>
                        ))}
                    </div>
                    <div className='modal-footer'>
                        <button className='close-button' style={{marginRight:'4rem'}} onClick={() => setIsStatusPopup(false)}>Cancel</button>
                        <button className='close-button' onClick={() => {
                            const selectedOption = document.querySelector('input[name="rejectionOption"]:checked');
                            if (selectedOption) {
                                changeStatus(selectedOption.value);
                            }
                        }}>Select</button>
                    </div>
                </div>
            </div>
          )}

        {isRewardPopup && (
            <div className='modal-overlay'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4>Give Reward Coins</h4> 
                    </div>
                    <div className='modal-body'>
                        <div className='pvwdb'>
                            <img src={ownerInfo.image} alt='Fyre Search User Profile' />
                            <div>
                                <p style={{fontSize:'18px'}}>{ownerInfo.title}</p>
                                <p>{ownerInfo.username}</p>
                            </div>
                        </div>
                        <div class="form-floating pvcib">
                            <input type="number" id="coins" class="form-control" name="coins" required="true" />
                            <label htmlFor="coins">Enter coins here</label>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button className='close-button' style={{marginRight:'4rem'}} onClick={() => setIsRewardPopup(false)}>
                            Decline
                        </button>
                        <button className='close-button' onClick={() => {
                            const selectedOption = document.querySelector('input[name="coins"]');
                            if (selectedOption) {
                                giveReward(selectedOption.value);
                            }
                        }}>Proceed</button>
                    </div>
                </div>
            </div>
          )}

        </div>
    )
}

export default Tagged;