import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Default = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const sourceId = sessionStorage.getItem('captain_id');
    if (!sourceId) {
      navigate('/login');
    }
    else {
        navigate('/home'); 
    }
  }, [navigate]);

};

export default Default;
