import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageCropper = ({ onCrop, onClose }) => {
  const [image, setImage] = useState(null);
  const cropperRef = useRef(null);

  const handleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const getCroppedImage = () => {
    const cropper = cropperRef.current.cropper;
    const croppedDataUrl = cropper.getCroppedCanvas().toDataURL('image/jpeg');
    onCrop(croppedDataUrl);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header mb-4">
            <span>UPLOAD LOGO</span>
            <span className="popup-close" onClick={onClose}>&times;</span>
        </div>
        <button class="container-btn-file" onChange={handleImage}>
          <svg
            aria-hidden="true"
            stroke="currentColor"
            stroke-width="2"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-width="2"
              stroke="#fffffff"
              d="M13.5 3H12H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H11M13.5 3L19 8.625M13.5 3V7.625C13.5 8.17728 13.9477 8.625 14.5 8.625H19M19 8.625V11.8125"
              stroke-linejoin="round"
              stroke-linecap="round"
            ></path>
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="2"
              stroke="#fffffff"
              d="M17 15V18M17 21V18M17 18H14M17 18H20"
            ></path>
          </svg>
          Upload Image
          <input class="file" accept="image/*" type="file" />
        </button>

        {image && (
          <div>
            <Cropper
              src={image}
              style={{ height: 400, width: '100%', marginBottom: '15px', marginTop: '15px', }}
              aspectRatio={1}
              guides={false}
              ref={cropperRef}
              viewMode = {2}
            />
            <button type='button' className='black-btn' onClick={getCroppedImage}>Crop Image</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCropper;
