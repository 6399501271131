import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import ListingPopup from './ListingPopup';

const Topbar = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [captainProfile, setCaptainProfile] = useState('');

  const captainData = getItemFromLocalStorage('captainProfile');
  //const listing_id = getItemFromLocalStorage('listing_id');

  useEffect(() => {
    const captainData = getItemFromLocalStorage('captainProfile');
    if (captainData) {
      setCaptainProfile(captainData);
    }
  }, []);

  const fetchListing = useCallback(async () => {

    let formData = new FormData();
    formData.append('token', captainData.captain_access_token);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.getListing}`,
        data: formData
      });

      if (response.data.items && Array.isArray(response.data.items)) {
        const filteredData = response.data.items.filter(item => item.is_profile_verified === 1);
        console.log(filteredData);
        setData(filteredData);
        setShowModal(true);
      } else {
        setError(response.message || 'Error in fetching data.');
        <Link to="/logout" />
      }
    } catch (error) {
      setError('Error fetching My Subscriptions.');
    }
  }, [captainData]);

  /*useEffect(() => {
    if (!listing_id) {
      fetchListing();
    }
  }, [listing_id, fetchListing]);*/

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">

      <div className="d-flex align-items-center justify-content-between">
        <Link to="/home" className="logo d-flex align-items-center">
          <span className="d-none d-lg-block">Fyre Search</span>
        </Link>
        {/*<i className="bi bi-list toggle-sidebar-btn"></i>*/}
      </div>

      <nav className="header-nav ms-auto">
          
        <ul className="d-flex align-items-center">

        <li className='pe-3'>
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="#" onClick={fetchListing}>
              <img src={'/assets/img/icons/store_icon.svg'} alt="store icon" className="noti-icon" />
            </Link>
          </li>

          <li className='pe-3'>
            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/notifications">
              <img src={'/assets/img/icons/bell.svg'} alt="Notification" className="noti-icon" />
            </Link>
          </li>

          <li className="nav-item dropdown pe-3">

            <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="/home" data-bs-toggle="dropdown">
              <img src={captainProfile.captain_logo} alt="Profile" className="rounded-circle" />
            </Link>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">

              <li>
                <Link className="dropdown-item d-flex align-items-center" to="/logout">
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Log Out</span>
                </Link>
              </li>

            </ul>
          </li>

        </ul>
      </nav>
      <ListingPopup show={showModal} onClose={() => setShowModal(false)} data={data} />

    </header>
    
  );
};

export default Topbar;
