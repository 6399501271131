import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImageUploading from 'react-images-uploading';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

function AddPost() {
    const [postType, setPostType] = useState(null);
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoURL, setVideoURL] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [caption, setCaption] = useState('');
    const [loading, setLoading] = useState('');
    const maxNumber = 10;

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData ? captainData.captain_access_token : null;
    const businessListingId = getItemFromLocalStorage('listing_id');

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const postTypeParam = params.get('postType');
        if (postTypeParam) {
          setPostType(Number(postTypeParam));
        }
    }, [location.search]);

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
        if (imageList.length > 0) {
            setSelectedImage(imageList[0]);
        } else {
            setSelectedImage(null);
        }
    };

    const onImageClick = (image) => {
        setSelectedImage(image);
    };

    const onVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const videoURL = URL.createObjectURL(file);
            setVideoFile(file);
            setVideoURL(videoURL);

            // Generate thumbnail
            const videoElement = document.createElement('video');
            videoElement.src = videoURL;
            videoElement.addEventListener('loadeddata', () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                const thumbnailURL = canvas.toDataURL('image/jpeg');
                setThumbnail(thumbnailURL);
            });
        } else {
            setVideoFile(null);
            setVideoURL(null);
            setThumbnail(null);
        }
    };

    const onPostClick = async () => {
        if (!token) {
            toast.error('Token is missing.');
            return;
        }
        if (!businessListingId) {
            toast.error('Business listing ID is missing.');
            return;
        }
        if (postType === 1 && images.length === 0) {
            toast.error('Please upload an image.');
            return;
        }
        if (postType === 2 && !videoFile) {
            toast.error('Please upload a video.');
            return;
        }

        setLoading(true);

        let formData = new FormData();
        formData.append('token', token);
        formData.append('profile_id', businessListingId);
        formData.append('profile_type', 'listing');
        formData.append('photo_caption', caption);

        let url = '';
        if (postType === 1) {
            url = `${params.baseURL}${params.endpoints.postPhotos}`;
            images.forEach((image, index) => {
                const byteString = atob(image.data_url.split(',')[1]);
                const mimeString = 'image/jpeg';
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ab], { type: mimeString });
                formData.append(`img${index + 1}`, blob, `image${index}.jpg`);
            });
        } else if (postType === 2) {
            url = `${params.baseURL}${params.endpoints.postVideo}`;
            formData.append('video0', videoFile);
            if (thumbnail) {
                const thumbnailBlob = await fetch(thumbnail).then(res => res.blob());
                formData.append('image0', thumbnailBlob, 'thumbnail.jpg');
            }
        }

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: url,
                data: formData
            });

            console.log('Response:', response);

            if (response.data.status === 'completed') {
                setSelectedImage(null);
                setVideoFile(null);
                setVideoURL(null);
                setThumbnail(null);
                Swal.fire({
                    icon: 'success',
                    text: 'Your post uploaded successfully.!',
                    confirmButtonText: 'OK'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/home';
                    }
                });
            } else if (response.data.status === 'error') {
                toast.error(response.data.list);
            } else {
                toast.error('Error in saving data.');
            }
        } catch (err) {
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="post">
            <Helmet>
                <title>Add Post</title>
            </Helmet>

            {loading && <Loader />}

            {postType === 1 && (
                <div>
                    <div className='lsertbox'>
                        <h4>Add Photos</h4>
                    </div>
                    <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemove,
                            isDragging,
                            dragProps,
                        }) => (
                            <div>
                                <div className='uploaded_image_box'>
                                    {selectedImage ? (
                                        <div className="image-preview">
                                            <img src={selectedImage['data_url']} alt="Selected" />
                                        </div>
                                    ) : (
                                        <div className="image-preview">
                                            <img src={'/assets/img/icons/gallery_icon.svg'} alt="Selected" />
                                        </div>
                                    )}
                                </div>
                                <div className="upload__image-wrapper">
                                    <button
                                        className='upload__btn'
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        <img src={'/assets/img/icons/gallery_icon.svg'} alt='Upload Post' />
                                    </button>
                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item">
                                            <img src={image['data_url']} onClick={() => onImageClick(image)} alt="" />
                                            <div className="image-item__btn-wrapper">
                                                <button className='img_delete_btn' onClick={() => onImageRemove(index)}>
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </ImageUploading>
                    <span className='side-line'></span>
                    <div className='caption_box'>
                        <label htmlFor='caption' style={{ fontSize: '14px', marginBottom: '8px' }}>Write a caption</label>
                        <textarea
                            id='caption'
                            placeholder='Write a caption'
                            name='postContent'
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                        />
                    </div>
                </div>
            )}

            {postType === 2 && (
                <div>
                    <div className='lsertbox'>
                        <h4>Add Video</h4>
                    </div>
                    <div className='uploaded_image_box'>
                        {videoURL ? (
                            <div className="image-preview">
                                <video controls src={videoURL} />
                            </div>
                        ) : (
                            <div className="image-preview">
                                <img src={'/assets/img/icons/gallery_icon.svg'} alt="" />
                            </div>
                        )}
                    </div>
                    <div className="upload__image-wrapper">
                        <button
                            className='upload__btn'
                            onClick={() => document.getElementById('videoUpload').click()}
                        >
                            <img src={'/assets/img/icons/gallery_icon.svg'} alt='Upload Video' />
                        </button>
                        <input
                            type='file'
                            id='videoUpload'
                            style={{ display: 'none' }}
                            accept='video/*'
                            onChange={onVideoChange}
                        />
                        {thumbnail && (
                            <div className="image-item">
                            <img src={thumbnail} alt="" />
                            <div className="image-item__btn-wrapper">
                                <button className='img_delete_btn' >
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                        )}
                    </div>
                    <span className='side-line'></span>
                    <div className='caption_box'>
                        <label htmlFor='caption' style={{ fontSize: '14px', marginBottom: '8px' }}>Write a caption</label>
                        <textarea
                            id='caption'
                            placeholder='Write a caption'
                            name='postContent'
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                        />
                    </div>
                </div>
            )}

            <div style={{ margin: '1rem 0' }}>
                <button className='post_btn' onClick={onPostClick}>Post</button>
            </div>
        </div>
    );
}

export default AddPost;
