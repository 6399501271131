import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';

const SelectDataPopup = ({ isOpen, onClose, onSelect, onDone, selectedItems, activeType, activeData }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!activeType) return;
    const fetchData = async () => {
        try {
            const response = await axios({
                method: 'post',
                headers: {
                'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.baseData}`,
            });

            if (response.data.status === 'found') {
                setData(response.data[activeType]);
                setFilteredData(response.data[activeType]);
            } else {
                setError(response.message || 'Error in fetching data.');
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
            setError('Error in fetching data.');
        }
    };
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, activeType]);

  useEffect(() => {
    if (!activeData) return;
    setFilteredData(
      data.filter(item =>
        item[`${activeData}_name`] && item[`${activeData}_name`].toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, data, activeData]);

  const isItemSelected = item => {
    if (!selectedItems || selectedItems.length === 0) return false;
    return selectedItems.some(selected => selected[`${activeData}_id`] === item[`${activeData}_id`]);
  };

  if (!isOpen || !activeType) {
    return null;
  }

  return (
    <div className="custom-model-main">
      <div className="custom-model-inner">
        <div className='out-search-box'>
          <span className="sc-btn" onClick={onClose}>
            &times;
          </span>
          <input
            type="text"
            className="form-control"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        {error && <p>Error: {error}</p>}
        <div className="api-data-list">
          {filteredData.map(item => (
            <div className='adlb' key={item[`${activeData}_id`]}>
              <input
                type="checkbox"
                id={`${item[`${activeData}_id`]}_${activeType}`}
                value={item[`${activeData}_id`]}
                checked={isItemSelected(item)}
                onChange={() => onSelect(activeType, item)}
              />
              <label htmlFor={`${item[`${activeData}_id`]}_${activeType}`}>{item[`${activeData}_name`]}</label>
            </div>
          ))}
        </div>
        <div className='sctcb-box'>
          <button type="button" className="done-btn" onClick={onDone}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default SelectDataPopup;
