import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';

const ExtraNotificationPopup = ({ isOpen, onClose, onSelect, onDone, selectedItems }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const captainData = getItemFromLocalStorage('captainProfile');
  const token = captainData.captain_access_token;

  const handlePageChange = (page) => {
    setCurrentPage(page);
};

  useEffect(() => {
    const fetchData = async () => {
      
      const formData = new FormData();
      formData.append('token', token);
      formData.append('country_code', 'IN');
      if(searchTerm) {
        formData.append('keyword', searchTerm);
      }
      try {
        const response = await axios({
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            url: `${params.baseURL}${params.endpoints.getCitiesItem}?page=${currentPage}`,
            data: formData,
        });

        if (response.data.items && Array.isArray(response.data.items)) {
          setFilteredData(response.data.items);
          setTotalPages(response.data._meta.pageCount);
        } else {
          setError(response.message || 'Error in fetching data.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, token, searchTerm, currentPage]);

  const isItemSelected = item => {
    if (!selectedItems || selectedItems.length === 0) return false;
    return selectedItems.some(selected => selected.data_id === item.data_id);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="custom-model-main">
      <div className="custom-model-inner">
        <div className='out-search-box'>
          <span className="sc-btn" onClick={onClose}>
            &times;
          </span>
          <input
            type="text"
            className="form-control"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </div>
        {error && <p>Error: {error}</p>}
        <div className="api-data-list">
          {filteredData.map(item => (
            <div style={{padding:'15px 0', borderBottom: '1px solid #d3d3d3'}} key={item.data_id}>
              <div className='pnedbox'>
                <div className='pnexitbox'>
                  <img src={'/assets/img/icons/pn_location.svg'} alt='' className='pneximg' />
                  <div style={{textAlign: 'left'}}>
                    <p>{item.data_name}</p>
                    <span style={{fontSize:'14px'}}>{item.data_info}</span>
                  </div>
                </div>
                <input
                  type="checkbox"
                  className='form-check-input'
                  id={`${item.data_id}_workplace`}
                  name='workplace'
                  value={item.data_id}
                  checked={isItemSelected(item)}
                  onChange={() => onSelect(item)}
                />
              </div>
            </div>
          ))}
          {totalPages > 1 && (
            <div className="pagination">
                <button 
                    type='button'
                    onClick={() => handlePageChange(1)}
                    className={currentPage === 1 ? 'page-btn disabled' : 'page-btn'}
                    disabled={currentPage === 1}
                >
                    First
                </button>
                <button 
                    type='button'
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={currentPage === 1 ? 'page-btn disabled' : 'page-btn'}
                    disabled={currentPage === 1}
                >
                    Prev
                </button>
                {currentPage > 1 && (
                    <button 
                        type='button'
                        onClick={() => handlePageChange(currentPage - 1)}
                        className='page-btn'
                    >
                        {currentPage - 1}
                    </button>
                )}
                <button 
                    type='button'
                    onClick={() => handlePageChange(currentPage)}
                    className='page-btn active'
                >
                    {currentPage}
                </button>
                {currentPage < totalPages && (
                    <button 
                        type='button'
                        onClick={() => handlePageChange(currentPage + 1)}
                        className='page-btn'
                    >
                        {currentPage + 1}
                    </button>
                )}
                <button 
                    type='button'
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={currentPage === totalPages ? 'page-btn disabled' : 'page-btn'}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
                <button 
                    type='button'
                    onClick={() => handlePageChange(totalPages)}
                    className={currentPage === totalPages ? 'page-btn disabled' : 'page-btn'}
                    disabled={currentPage === totalPages}
                >
                    Last
                </button>
            </div>
          )}
        </div>
        <div className='sctcb-box'>
          <button type="button" className="done-btn" onClick={onDone}>Done</button>
        </div>
      </div>
    </div>
  );
};

export default ExtraNotificationPopup;
