const Params = {
    weburl : 'http://localhost:3000',
    baseURL: 'https://app.statustag.com/sp/api/v1',
    endpoints: {
      createOtp : '/otp/create-otp',
      verifyOtp : '/otp/verify-otp',
      isUserExist : '/captain-profile/is-user-exists',
      saveProfile : '/captain-profile/save-profile',
      updateProfile : '/captain-profile/update-profile',
      getProfile : '/captain-profile/get-profile',
      saveBusiness : '/business-listing/save-profile',
      updateBusiness : '/business-listing/update-profile',
      verifyGst : '/captain-profile/verify-gstin',
      claimLocation : '/locations/find-cafes-for-claim',
      findLatLng : '/locations/find-place-lat-lng',
      findByLatLng : '/locations/find-place-by-lat-lng',
      categoryData : '/business-listing/category-data',
      baseData : '/business-listing/base-data',
      getListing : '/business-listing/list',
      getListingDetails : '/business-listing/get-listing',
      getPublicProfile : '/business-profile/get-profile-public',
      getCheckenList : '/business-profile/checkin-list',
      getUserProfile : '/customer-profile/get-profile-public',
      userTaggedPhotos : '/customer-photos/public-list',
      userSaveRating : '/customer-profile/save-rating',
      userUpdateRating : '/customer-profile/update-rating',
      getBusinessResource : '/business-listing/check-for-resource',
      getListingPhotos : '/cafe-photos/list',
      getSubscriptions : '/subscription/get-subscriptions',
      checkCoupon : '/affiliates/check-coupon',
      subscribeIt : '/subscription/subscribe-it',
      mySubscriptions : '/subscription/my-subscriptions',
      renewSubscription : '/subscription/renew-my-subscription',
      renewalHistory : '/subscription/my-subscription-renewal-history',
      subscriptionDetail : '/subscription/get-subscription-detail',
      verifyPg : '/subscription/verify-pg',
      crowdPuller : '/crowd-puller/offers',
      activateCrowdPuller : '/crowd-puller/pull',
      taggedPhotos : '/business-profile/tagged-photo-list',
      followFollowingList : '/business-profile/follow-following-list',
      updateFollowerRequest : '/business-profile/update-request-status',
      getCaptainCategory : '/notification/get-captain-category',
      notificationList : '/notification/notification-list',
      rejectionOptions : '/cafe-photos/get-rejection-options',
      rejectPhoto : '/cafe-photos/reject-tagged-photo',
      rewardPoint : '/cafe-photos/give-reward-point-to-photo',
      ratingList : '/business-profile/rating-list-view-for-captain',
      postPhotos : '/cafe-photos/multiple-save-entry',
      updatePhoto : '/cafe-photos/multiple-update-entry',
      deletePhoto : '/cafe-photos/delete-entry',
      likeList : '/cafe-photos/likes-list',
      saveLike : '/cafe-photos/save-like',
      postVideo : '/cafe-videos/save-entry',
      updateVideo : '/cafe-videos/update-entry',
      commentList : '/customer-photos/comments-list',
      saveComment : '/customer-photos/save-comment',
      deleteComment : '/customer-photos/remove-comment',
      commentRepliesList : '/customer-photos/replies-list',
      saveReply : '/customer-photos/save-reply',
      removeReply : '/customer-photos/remove-reply',
      replyCommentInfo : '/customer-photos/reply-comment-info',
      bannerList : '/banner-entry/list',
      bannerStatusUpdate : '/banner-entry/status-update',
      bannerSaveEntry : '/banner-entry/save-entry',
      bannerUpdateEntry : '/banner-entry/update-entry',
      bannerBaseData : '/banner-entry/get-base-data',
      bannerDelete : '/banner-entry/delete-entry',
      menuList : '/menu-entry/list',
      menuBaseData : '/menu-entry/get-base-data',
      menuEntry : '/menu-entry/save-entry',
      menuUpdateEntry : '/menu-entry/update-entry',
      menuStatusUpdate : '/menu-entry/status-update',
      menuDelete : '/menu-entry/delete-entry',
      acceptFoodOrder : '/menu-entry/accept-food-order',
      tableSaveEntry : '/table-entry/save-entry',
      tableUpdateEntry : '/table-entry/update-entry',
      tableList : '/table-entry/list',
      tableBaseData : '/table-entry/get-base-data',
      tableStatusUpdate : '/table-entry/status-update',
      tableDelete : '/table-entry/delete-entry',
      acceptBooking : '/table-entry/accept-booking',
      offerList : '/offer-entry/list',
      offerSaveEntry : '/offer-entry/save-entry',
      offerUpdateEntry : '/offer-entry/update-entry',
      offerStatusUpdate : '/offer-entry/status-update',
      offerDelete : '/offer-entry/delete-entry',
      pushEntryList : '/push-entry/list',
      pushEntryStatusUpdate : '/push-entry/status-update',
      pushEntrySaveEntry : '/push-entry/save-entry',
      pushEntryUpdateEntry : '/push-entry/update-entry',
      pushEntryDelete : '/push-entry/delete-entry',
      pushEntryDetails : '/push-entry/details',
      pushEntryBaseData : '/push-entry/get-base-data',
      getTimeList : '/push-entry/get-time-list',
      getStateList : '/push-entry/get-state-list',
      getCityList : '/push-entry/get-city-list',
      calculateTargetAudience : '/push-entry/calculate-target-audience',
      extraDataItems : '/extra-data/get-w-h-s-items',
      getCitiesItem : '/extra-data/get-cities-items',
    },
  };
  
  export default Params;
  