import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';

function Notifications() {
    const [activeTab, setActiveTab] = useState(1); 
    const [loading, setLoading] = useState(true);
    const [followList, setFollowList] = useState([]);
    const [captainCategory, setCaptainCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const formData = new FormData();
            formData.append('listing_id', businessListingId);
            formData.append('token', token);
            formData.append('type', 'listing');
            formData.append('category', activeTab);

            const url = `${params.baseURL}${params.endpoints.notificationList}?page=${currentPage}`;

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: url,
                    data: formData
                });
                if (response.data.items && Array.isArray(response.data.items)) {
                    setFollowList(response.data.items);
                    setTotalPages(response.data._meta.pageCount);
                } else {
                    console.log('Data not Found.');
                }
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        const getCaptainCategory = async () => {
            const formData = new FormData();
            formData.append('token', token);
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getCaptainCategory}`,
                    data: formData
                });
                if (response.data.status === 'found') {
                    setCaptainCategory(response.data.notification_category);
                } else {
                    console.log('Data not Found.');
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchData();
        getCaptainCategory();
    }, [activeTab, currentPage, businessListingId, token]);

    return (
        <div>
            <div className="ntab-buttons d-flex">
                {captainCategory.map((list) => (
                    <button 
                        key={list.id}
                        onClick={() => handleTabClick(list.id)} 
                        className={activeTab === list.id ? 'tb-btn tb-active' : 'tb-btn'}
                    >
                        {list.title}
                    </button>
                ))}
            </div>
            <span className='side-line'></span>
            <div className="ntab-content">
                {loading ? (
                    <Loading />
                ) : (
                    <div className="tab-content-item">
                        <div>
                            {followList.length > 0 ? (
                                <>
                                    {followList.map((data) => (
                                        <div className='follow-followes-list' key={data.notification_id}>
                                            <div className='fflb'>
                                                <img 
                                                    src={data.sender_info?.image ? data.sender_info.image : '/assets/img/icons/default_user.svg'}
                                                    alt="Fyre Search User" 
                                                />
                                                <div className='ffl-txt'>
                                                    <p>{data.message_title}</p>
                                                    <p>{data.message}</p>
                                                    <p className='noti-time'>{data.created_time_as_text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {totalPages > 1 && (
                                        <div className="pagination">
                                            <button 
                                                type='button'
                                                onClick={() => handlePageChange(1)}
                                                className={currentPage === 1 ? 'page-btn disabled' : 'page-btn'}
                                                disabled={currentPage === 1}
                                            >
                                                First
                                            </button>
                                            <button 
                                                type='button'
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                className={currentPage === 1 ? 'page-btn disabled' : 'page-btn'}
                                                disabled={currentPage === 1}
                                            >
                                                Prev
                                            </button>
                                            {currentPage > 1 && (
                                                <button 
                                                    type='button'
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    className='page-btn'
                                                >
                                                    {currentPage - 1}
                                                </button>
                                            )}
                                            <button 
                                                type='button'
                                                onClick={() => handlePageChange(currentPage)}
                                                className='page-btn active'
                                            >
                                                {currentPage}
                                            </button>
                                            {currentPage < totalPages && (
                                                <button 
                                                    type='button'
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    className='page-btn'
                                                >
                                                    {currentPage + 1}
                                                </button>
                                            )}
                                            <button 
                                                type='button'
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                className={currentPage === totalPages ? 'page-btn disabled' : 'page-btn'}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </button>
                                            <button 
                                                type='button'
                                                onClick={() => handlePageChange(totalPages)}
                                                className={currentPage === totalPages ? 'page-btn disabled' : 'page-btn'}
                                                disabled={currentPage === totalPages}
                                            >
                                                Last
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='empty-section'>
                                    <img src={'/assets/img/icons/no_follower.svg'} alt='Fyre Search' />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Notifications;
