import React, { useState } from 'react';
import { setWithExpiry } from '../utils/storageUtils';

const ListingPopup = ({ show, onClose, data }) => {

    const [selectedListingId, setSelectedListingId] = useState(null);

    const handleSetClick = () => {
        if (selectedListingId) {
            setWithExpiry('listing_id', selectedListingId);
            onClose();
            window.location.reload();
        } else {
            alert('Please select a listing.');
        }
    };

    const handleChange = (event) => {
        setSelectedListingId(event.target.value);
    };

    if (!show) {
        return null;
    }

    return (
        <div className="popup">
            <div className='popup-content'style={{width:'100%', margin: '30px', position: 'relative'}}>
                <div className='tcp-header'>
                    <div style={{width:'400px'}}>
                        <img src={'/assets/img/change_listing_logo.svg'} style={{width:'100%'}} alt='' />
                    </div>
                    <span className='popup-close' onClick={onClose}>
                        <img src={'/assets/img/icons/close_icon.svg'} style={{width:'100%'}} alt='' />
                    </span>
                </div>
                <div className="business-lists">
                    <div class="mbl-box">
                        {data.length > 0 ? (
                            data.map(item => (                                                
                                <label className="form-check-label mbl-lable" htmlFor={`listing-${item.listing_id}`}>
                                    <input className="form-check-input mbl-input" type="radio" name="business_listing" id={`listing-${item.listing_id}`}  value={item.listing_id} onChange={handleChange} />
                                    <span class="mbl-tile">
                                        <span class="mbl-icon">
                                            <img src={item.business_profile_logo_url} alt="Profile" className="rounded-circle" />
                                        </span>
                                        <span class="mbl-bname">{item.business_name}</span>
                                    </span>
                                </label>                         
                            ))
                        ) : (
                            <p>No Business Listing Found</p>
                        )}
                    </div>
                </div>
                <div className='modal-footer' style={{justifyContent:'center'}}>
                    <button className="close-button rate-btn w-100" onClick={handleSetClick}>set</button>
                </div>
            </div>
        </div>
    )
}

export default ListingPopup;