import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loader from '../components/Loader';

function EditPost() {
    const { id } = useParams();
    const parsedId = parseInt(id, 10);
    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    const [listingPhotos, setListingPhotos] = useState([]);
    const [photoDetails, setPhotoDetails] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [caption, setCaption] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState('');

    const errorRef = useRef(null);
    const successMessageRef = useRef(null);

    useEffect(() => {
        if ((error && errorRef.current) || (successMessage && successMessageRef.current)) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error, successMessage]);

    useEffect(() => {
        const fetchListingPhotos = async () => {
            if (businessListingId && token) {
                const formData = new FormData();
                formData.append('profile_id', businessListingId);
                formData.append('token', token);
                formData.append('profile_type', 'listing');

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        url: `${params.baseURL}${params.endpoints.getListingPhotos}`,
                        data: formData,
                    });
                    if (response.data.items && Array.isArray(response.data.items)) {
                        setListingPhotos(response.data.items);
                    } else {
                        console.log('Listing not found');
                    }
                } catch (error) {
                    console.log(error.message);
                }
            }
        };

        fetchListingPhotos();
    }, [businessListingId, token]);

    useEffect(() => {
        if (listingPhotos.length > 0 && !photoDetails) {
            const photo = listingPhotos.find(list => list.photo_id === parsedId);
            setPhotoDetails(photo);
            setCaption(photo.photo_caption);
        }
    }, [listingPhotos, parsedId, photoDetails]);

    const onImageClick = (image) => {
        setSelectedImage(image);
    };

    const onPostClick = async () => {
        if (!photoDetails) return;

        const url = photoDetails.media_type === 'V' ? 
            `${params.baseURL}${params.endpoints.updateVideo}` : 
            `${params.baseURL}${params.endpoints.updatePhoto}`;

        let formData = new FormData();
        formData.append('token', token);
        formData.append('profile_id', businessListingId);
        formData.append('profile_type', 'listing');
        formData.append('photo_caption', caption);
        formData.append('photo_id', parsedId);

        setLoading(true);

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: url,
                data: formData
            });

            if (response.data.status === 'completed') {
                setSuccessMessage('Your post updated successfully.');
            } else if (response.data.status === 'error') {
                setError(response.data.list);
            } else {
                setError('Error in updating data.');
            }
        } catch (err) {
            setError('An error occurred while saving data.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && <Loader />}
            {successMessage && <div className="alert alert-success mb-4" ref={successMessageRef}>{successMessage}</div>}
            {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 
            <div className='lsertbox'>
                <h4>Edit Post</h4>
            </div>
            {photoDetails ? (
            <>
                <div className='uploaded_image_box'>
                    {selectedImage ? (
                        <div className="image-preview">
                            <img src={selectedImage} alt="post" />
                        </div>
                    ) : (
                        <div className="image-preview">
                            {photoDetails.media_type === 'V' ? (
                                <video controls src={photoDetails.photo_image_url}>
                                </video>
                            ) : (
                                <img src={photoDetails.photo_image_url} alt="post" />
                            )}
                        </div>
                    )}
                </div>
                <div className="upload__image-wrapper">
                    {photoDetails.photo_items.map((item, index) => (
                        <div key={index} className='image-item'>
                            <img src={item.photo_image_url} onClick={() => onImageClick(item.photo_image_url)} alt='Upload Post' />
                        </div>
                    ))}
                </div>
                <span className='side-line'></span>
                <div className='caption_box'>
                    <label htmlFor='caption' style={{ fontSize: '14px', marginBottom: '8px' }}>Write a caption</label>
                    <textarea placeholder='Write a caption' name='postContent' value={caption} onChange={(e) => setCaption(e.target.value)}>
                        {caption}         
                    </textarea>
                </div>
                <div style={{ margin: '1rem 0' }}>
                    <button className='post_btn' onClick={onPostClick}>Update</button>
                </div>
            </>
            ) : (
                <Loader />
            )}
        </div>
    );
}

export default EditPost;
