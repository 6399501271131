import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import params from './config/Params';
import { getItemFromLocalStorage } from './utils/storageUtils';

const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children, type, moduleFeature }) => {
    const [subscription, setSubscription] = useState(null);

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData ? captainData.captain_access_token : null;
    const businessListingId = getItemFromLocalStorage('listing_id');

    useEffect(() => {
        const getSubscription = async () => {
            if (token) {
                const formData = new FormData();
                formData.append('token', token);
                formData.append('listing_id', businessListingId);
                formData.append('type', type);
                formData.append('module', moduleFeature);

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.getBusinessResource}`,
                        data: formData
                    });

                    if (response.data) {
                        setSubscription(response.data);
                    } else {
                        console.log(response.message || 'Error in fetching data.');
                    }
                } catch (error) {
                    console.log('Error fetching My Subscriptions.');
                }
            }
        };
        getSubscription();
    }, [token, businessListingId, type, moduleFeature]);

    return (
        <SubscriptionContext.Provider value={subscription}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export const useSubscription = () => {
    return useContext(SubscriptionContext);
};
