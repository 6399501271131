import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';

const SearchOutletPopup = ({ latitude, longitude, onClose, onClaimLocation, onNotOnList }) => {
  const [searchText, setSearchText] = useState('');
  const [outlets, setOutlets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const searchOutlets = async () => {
      setLoading(true);
      let formData = new FormData();
        formData.append('lat', latitude);
        formData.append('lng', longitude);
        formData.append('country', 'in');
        formData.append('text', searchText);

        try {
        const response = await axios({
            method: 'post',
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            url: `${params.baseURL}${params.endpoints.claimLocation}`,
            data: formData
        });

        if (response.data.status === 'result') {
            setOutlets(response.data.places_list);
            setError(null);
        } else {
            setError(response.data.message);
        }
      } catch (error) {
        console.log('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    if (searchText.length >= 3) {
      searchOutlets();
    } else {
      setOutlets([]);
    }
  }, [latitude, longitude, searchText]);
  
  return (
    <div className="custom-model-main">
      <div className="custom-model-inner" style={{minHeight:'90vh'}}>
        <div className='out-search-box'>
            <span className="sc-btn" onClick={onClose}>
            &times;
            </span>
            <input
            type="text"
            className="form-control"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="SEARCH OUTLET"
            />
        </div>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <ul className='outlet-list-box'>
          {outlets.map((outlet) => (
            <li className='outlet-list-data' key={outlet.id}>
                <h4>{outlet.title}</h4>
                <p>{outlet.description}</p>
                <div>
                    <button type='button' className='claim-btn' onClick={() => onClaimLocation(outlet)}>Claim Location</button>
                </div>
            </li>
          ))}
        </ul>
        <div className='notl-box'>
            <button type='button' className='notl-btn' onClick={() => onNotOnList(searchText)}>Not on the list</button>
        </div>
      </div>
    </div>
  );
};

export default SearchOutletPopup;
