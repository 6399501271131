import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useGeolocated } from 'react-geolocated';
import { debounce } from 'lodash';
import Loading from './Loading';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultCenter = {
  lat: 28.64231,
  lng: 77.22229
};

const GoogleMapComponent = ({ setAddress, setLatitude, setLongitude, onClose }) => {
  const [center, setCenter] = useState(defaultCenter);
  const [localAddress, setLocalAddress] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const mapRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC4xmFbX2XiP2dbZW5LUoSjw2qR-o2utmU",
  });

  useEffect(() => {
    if (coords) {
      const userLocation = {
        lat: coords.latitude,
        lng: coords.longitude,
      };
      setCenter(userLocation);
    }
  }, [coords]);

  const fetchAddress = useCallback(debounce((lat, lng) => {
    setIsLoading(true);
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC4xmFbX2XiP2dbZW5LUoSjw2qR-o2utmU`)
      .then(response => response.json())
      .then(data => {
        if (data.results && data.results[0]) {
          setLocalAddress(data.results[0].formatted_address);
        } else {
          setLocalAddress('Address not found');
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching the address:', error);
        setLocalAddress('Error retrieving address');
        setIsLoading(false);
      });
  }, 500), []);
  
  const handleMapIdle = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      const lat = center.lat();
      const lng = center.lng();
      setCenter({ lat, lng });
      setLat(lat);
      setLng(lng);
      fetchAddress(lat, lng);
    }
  };

  useEffect(() => {
    return () => {
      fetchAddress.cancel();
    };
  }, [fetchAddress]);

  const handleSave = () => {
    setLatitude(lat);
    setLongitude(lng);
    setAddress(localAddress);
    onClose();
  };

  if (!isLoaded) {
    return <div><Loading /></div>;
  }

  return (
      <div className="popup" style={{overflow:'hidden'}}>
        <div className="popup-content">
            <div className='cpo-header'>
                <div className="address-display">
                    <h4>Choose your location</h4>
                </div>
                <span className='popup-close' onClick={onClose}>&times;</span>
            </div>
            <div style={{position:'fixed', width:'inherit', top:'105px', zIndex:'9'}}>
            {isLoading ? (
              <p>Loading address...</p>
            ) : (
              localAddress && <p className='map-address-box'>{localAddress}</p>
            )}
            </div>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              options={{streetViewControl: false}}
              onLoad={(map) => (mapRef.current = map)}
              onMouseUp={handleMapIdle}
            >
            </GoogleMap>
            <div
              style={{
                position: 'absolute',
                top: '64%',
                left: '50%',
                transform: 'translate(-50%, -100%)',
                pointerEvents: 'none',
              }}
            >
              <img src={'/assets/img/icons/location_marker.svg'} style={{width:'40px'}} alt="Map Marker" />
            </div>
            <button type='button' style={{position:'fixed', bottom:'-30px', width:'inherit'}} className='done-btn' onClick={handleSave}>Save & Proceed</button>
        </div>
      </div>
  );
};

export default GoogleMapComponent;
