import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import ReactStars from 'react-rating-stars-component';

const LiveCrowdUserRating = ({ itemId, onClose }) => {

    const [userDetails, setUserDetails] = useState('');
    const [ratingDetails, setRatingDetails] = useState('');
    const [ratingId, setRatingId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [ratings, setRatings] = useState({
        crowd: 0,      
        behaviour: 0,
        billing: 0,
        personality: 0,
    });
    const [comment, setComment] = useState('');

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    useEffect(() => {
        
        const fetchUserProfile = async () => {

            if (businessListingId && token) {
                setLoading(true);
                const formData = new FormData();
                formData.append('listing_id', businessListingId);
                formData.append('token', token);
                formData.append('id', itemId);
                formData.append('utype', 'captain');
                formData.append('listing_type', 'listing');

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.getUserProfile}`,
                        data: formData
                    });
                    if (response.data.status === 'found') {
                        setUserDetails(response.data.customer_profile_data);
                        setRatingDetails(response.data.customer_rating_info);
                        if (response.data.customer_rating_info) {
                            setRatingId(response.data.customer_rating_info.customer_rating_id);
                        }
                    }
                    else {
                        console.log('Profile not found.');
                    }
                } catch (error) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchUserProfile();

    }, [businessListingId, token, itemId]);

    useEffect(() => {
        if (ratingDetails) {
          setRatings({
            crowd: ratingDetails.crowd_rating,
            billing: ratingDetails.billing_rating,
            behaviour: ratingDetails.behaviour_rating,
            personality: ratingDetails.personality_rating,
          });
          setComment(ratingDetails.comments);
        }
    }, [ratingDetails]);

    const handleRatingChange = (newRating, name) => {
        setRatings({
          ...ratings,
          [name]: newRating,
        });
    };

    const handleSaveRating = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('rated_by_listing_id', businessListingId);
        formData.append('rated_by_listing_type', 'listing');
        formData.append('token', token);
        formData.append('crowd_rating', ratings.crowd);
        formData.append('behaviour_rating', ratings.behaviour);
        formData.append('billing_rating', ratings.billing);
        formData.append('personality_rating', ratings.personality);
        formData.append('comments', comment);

        let url;
        if(ratingDetails) {
            formData.append('customer_rating_id', ratingId);
            url = `${params.baseURL}${params.endpoints.userUpdateRating}`;
        }
        else {
            formData.append('customer_id', itemId);
            url = `${params.baseURL}${params.endpoints.userSaveRating}`
        }

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: url,
                data: formData
            });

            if (response.data.status === 'ok') {
                toast.success('Rating Updated Successfully.');
                onClose();
            } else {
                console.log('Failed to save rating.');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='popup'>
                <div className='popup-content mb-5'>
                {loading && <Loader />}
                {error && <div className="alert alert-danger">{error}</div>} 
                    <div className='cpo-header' style={{ margin: '0' }}>
                        <div>
                            <h4>Rate the Customer</h4>
                        </div>
                        <span className='popup-close' onClick={onClose}>&times;</span>
                    </div>
                    <div className='cposb' style={{marginTop: '20px'}}>
                        <div className='lcudpds'>
                            <div className='lcudirb'>
                                <img src={userDetails.customer_pic_image_url} alt='' />
                                <div className='lcudrb'>
                                    <p className='lcud_name'>{userDetails.customer_name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="rl-details rating-section">
                            <p>
                                <span>Crowd</span>
                                <label>
                                <ReactStars
                                    key={`crowd-${ratings.crowd}`}
                                    count={5}
                                    value={ratings.crowd}
                                    onChange={(newRating) => handleRatingChange(newRating, 'crowd')}
                                    size={24}
                                    activeColor="#880d4e"
                                />
                                </label>
                            </p>
                            <p>
                                <span>Behaviour</span>
                                <label>
                                <ReactStars
                                    key={`behaviour-${ratings.behaviour}`}
                                    count={5}
                                    value={ratings.behaviour}
                                    onChange={(newRating) => handleRatingChange(newRating, 'behaviour')}
                                    size={24}
                                    activeColor="#880d4e"
                                />
                                </label>
                            </p>
                            <p>
                                <span>Billing</span>
                                <label>
                                <ReactStars
                                    key={`billing-${ratings.billing}`}
                                    count={5}
                                    value={ratings.billing}
                                    onChange={(newRating) => handleRatingChange(newRating, 'billing')}
                                    size={24}
                                    activeColor="#880d4e"
                                />
                                </label>
                            </p>
                            <p>
                                <span>Personality</span>
                                <label>
                                <ReactStars
                                    key={`personality-${ratings.personality}`}
                                    count={5}
                                    value={ratings.personality}
                                    onChange={(newRating) => handleRatingChange(newRating, 'personality')}
                                    size={24}
                                    activeColor="#880d4e"
                                />
                                </label>
                            </p>
                            
                            <div style={{marginTop:'20px'}}>
                                <span style={{display:'block', marginBottom:'10px'}}>REVIEW</span>
                                <textarea
                                    className='form-control'
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                ></textarea>
                            </div>
                            <div className='lcbs mt-4'>
                                <button onClick={handleSaveRating} className="lc-btn">Post</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default LiveCrowdUserRating