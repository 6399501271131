import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import LiveCrowdUserDetails from './LiveCrowdUserDetails';

const FollowersListPopup = ({ show, onClose }) => {

    const [activeTab, setActiveTab] = useState('requests');  
    const [requestsList, setRequestsList] = useState([]);
    const [followersList, setFollowersList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [liveCrowdUserModal, setLiveCrowdUserModal] = useState(false);
    const [liveCrowdUserId, setLiveCrowdUserId] = useState('');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleLiveCrowdUser = (id) => {
        setLiveCrowdUserModal(true);
        setLiveCrowdUserId(id);
    };
    
      const closeLiveCrowdUser = () => {
        setLiveCrowdUserModal(false);
    }

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            const captainData = getItemFromLocalStorage('captainProfile');
            const businessListingId = getItemFromLocalStorage('listing_id');
    
            const formData = new FormData();
            formData.append('profile_id', businessListingId);
            formData.append('token', captainData.captain_access_token);
            formData.append('utype', 'captain');
    
            try {
            if (activeTab === 'requests') {
                formData.append('type', 'pending');
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.followFollowingList}`,
                    data: formData
                });
                if (response.data.items && Array.isArray(response.data.items)) {
                    setRequestsList(response.data.items);
                }
                else {
                    setError('Data not Found.');
                }
            } else {
                formData.append('type', 'followers');
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.followFollowingList}`,
                    data: formData
                });
                if (response.data.items && Array.isArray(response.data.items)) {
                    setFollowersList(response.data.items);
                }
                else {
                    setError('Data not Found.');
                }
            }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, [activeTab, refresh]);

    const handleRequestStatus = async (status, followFollowingId) => {
        const captainData = getItemFromLocalStorage('captainProfile');

        const formData = new FormData();
        formData.append('id', followFollowingId);
        formData.append('token', captainData.captain_access_token);
        formData.append('status', status);
        formData.append('utype', 'captain');

        try {
            const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.updateFollowerRequest}`,
                data: formData
            });
            if (response.data.status === 'ok') {
                setRefresh(!refresh);
            }
            else {
                setError('Status Not Updated');
            }
        }catch (error) {
            setError(error.message);
        }
    };

    if (!show) {
        return null;
    }
    return (
    <>     
        <div className='popup'>
            <div className='popup-content'> 
                {error && <div className="alert alert-danger">{error}</div>} 
                <div className='cpo-header' style={{margin:'0'}}>
                    <div>
                        <h4>Followers / Following</h4>
                    </div>
                    <span className='popup-close' onClick={onClose}>&times;</span>
                </div>               
                <span className="side-line"></span>
                <div className="tab-buttons">
                    <button onClick={() => handleTabClick('requests')} className={activeTab === 'requests' ? 'tb-btn tb-active' : 'tb-btn'}>Requests</button>
                    <button onClick={() => handleTabClick('followers')} className={activeTab === 'followers' ? 'tb-btn tb-active' : 'tb-btn'}>Followers</button>
                </div>
                <div className="tab-content">
                    {loading ? (
                        <div>Loading...</div>
                        ) : activeTab === 'requests' ? (
                        <div className="tab-content-item">
                            <div>
                            {requestsList.length > 0 ? (<>
                                {requestsList.map((requests) => (
                                    <div className='follow-followes-list' key={requests.follow_following_id}>
                                        <div className='fflb' onClick={() => handleLiveCrowdUser(requests.source_profile_id)}>
                                            <img src={requests.image_url} alt="Fyre Search User" />
                                            <div className='ffl-txt'>
                                                <p>{requests.title}</p>
                                                <p>{requests.created_time_as_text}</p>
                                            </div>
                                        </div>
                                        {requests.request_status === 1 && (
                                            <div>
                                                <button className='req-btn accept' style={{marginBottom:'5px'}}
                                                    onClick={() => handleRequestStatus('accept', requests.follow_following_id)}>
                                                    Accept
                                                </button>
                                                <button className='req-btn reject'
                                                    onClick={() => handleRequestStatus('reject', requests.follow_following_id)}>
                                                    Reject
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>) : (<>
                                <div className='empty-section'>
                                    <img src={'/assets/img/icons/no_follower.svg'} alt='Fyre Search' />
                                </div>
                            </>)}
                            </div>
                        </div>
                        ) : (
                        <div className="tab-content-item">
                            <div>
                            {followersList.length > 0 ? (<>
                                {followersList.map((followers) => (
                                    <div className='follow-followes-list' key={followers.follow_following_id} >
                                        <div className='fflb' onClick={() => handleLiveCrowdUser(followers.source_profile_id)}>
                                            <img src={followers.image_url} alt="Fyre Search User" />
                                            <div className='ffl-txt'>
                                                <p>{followers.title}</p>
                                                <p>{followers.created_time_as_text}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>) : (<>
                                <div className='empty-section'>
                                    <img src={'/assets/img/icons/no_follower.svg'} alt='Fyre Search' />
                                </div>
                            </>)}
                            </div>
                        </div>
                    )}
                </div>

                {liveCrowdUserModal && 
                    <LiveCrowdUserDetails
                    itemId={liveCrowdUserId}
                    onClose={closeLiveCrowdUser}
                    />
                }

            </div>
        </div>
    </>
    )
}

export default FollowersListPopup;