import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import EditOffer from './EditOffer';
import Loading from '../components/Loading';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SubscriptionCheck from '../components/SubscriptionCheck';

function Offers() {

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    const [menuList, setMenuList] = useState([]);
    const [search, setSearch] = useState('');
    const [offerImage, setOfferImage] = useState('');
    const [title, setTitle] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [description, setDescription] = useState('');
    const [landingPage, setLandingPage] = useState('');
    const [status, setStatus] = useState(0);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isImageValid, setIsImageValid] = useState(true);
    const [itemId, setItemId] = useState('');
    const [itemName, setItemName] = useState('');
    const [isDeleteModal, setIsDeleteModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [isAddPopup, setIsAddPopup] = useState(false);

    const errorRef = useRef(null);

    useEffect(() => {
        if (error && errorRef.current) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error]);

    const fetchMenuList = useCallback(async () => {
        if (businessListingId && token) {
            setLoading(true);
            const formData = new FormData();
            formData.append('listing_id', businessListingId);
            formData.append('token', token);
            if (search) {
                formData.append('keyword', search);
            }

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.offerList}`,
                    data: formData
                });
                if (response.data.items && Array.isArray(response.data.items)) {
                    setMenuList(response.data.items);
                } else {
                    console.log('Offer list not found.');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    }, [businessListingId, token, search]);

    useEffect(() => {
        fetchMenuList();
    }, [fetchMenuList]);


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleOfferImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
            img.src = objectUrl;

            img.onload = () => {
                if (img.width === 1080 && img.height === 1080) {
                    setOfferImage(file);
                    setIsImageValid(true);
                } else {
                    toast.error('Image dimensions should be 1080px x 1080px.');
                    setOfferImage('');
                    setIsImageValid(false);
                }
                URL.revokeObjectURL(objectUrl);
            };
        }
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.checked ? 1 : 2);
    };

    const openEditModal = async (id) => {
        setItemId(id);
        setIsEditModal(true);
    }

    const handleEditModal = (e) => {
        setIsEditModal(false);
    }

    const handleAddData = async () => {

        if (!offerImage) {
            toast.error('Image cannot be blank');
            return;
        }
        
        if (!isImageValid) {
            toast.error('Image dimensions should be 1080px x 1080px.');
            return;
        }

        let formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('entry_pic', offerImage);
        formData.append('offer_coupon_code', couponCode);
        formData.append('offer_name', title);
        formData.append('offer_description', description);
        formData.append('offer_destination', landingPage);
        formData.append('offer_status', status);

        setLoader(true);
        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.offerSaveEntry}`,
                data: formData
            });

            if (response.data.status === 'completed') {
                Swal.fire({
                    icon: 'success',
                    text: 'Offer added successfully!',
                    confirmButtonText: 'OK'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/listing/offers';
                    }
                });
            } else if (response.data.status === 'error') {
                response.data.list.map((error) => (
                    toast.error(error)
                ));
            } else {
                setError('Error in saving data.');
            }
        } catch (err) {
            setError('An error occurred while saving data.');
        } finally {
            setLoader(false);
        }
    }

    const openDeleteModal = async (id, name) => {
        setItemId(id);
        setItemName(name);
        setIsDeleteModal(true);
    };

    const handleItemDelete = async () => {
        if (businessListingId && token) {
          const cData = new FormData();
          cData.append('token', token);
          cData.append('listing_id', businessListingId);
          cData.append('offer_id', itemId);
    
          try {
            const response = await axios({
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              url: `${params.baseURL}${params.endpoints.offerDelete}`,
              data: cData
            });
            if (response.data.status === 'completed') {
                setIsDeleteModal(false);
                toast.success('Offer deleted successfully.');
                fetchMenuList();
            }
            else if(response.data.status === 'error') {
              setError(response.data.list);
            }
            else {
              setError('Error Deleting Data.');
            }
          } catch (error) {
            setError(error.message);
          }
        }
    };

    const changeStatus = async (menuItemId, newStatus) => {
        if (businessListingId && token) {
            const cData = new FormData();
            cData.append('token', token);
            cData.append('listing_id', businessListingId);
            cData.append('offer_id', menuItemId);
            cData.append('onoff', newStatus);
      
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.offerStatusUpdate}`,
                data: cData
              });
              if (response.data.status === 'completed') {
                //toast.success('Offer status changed successfully.');
                setMenuList(prevList =>
                    prevList.map(item =>
                        item.offer_id === menuItemId ? { ...item, offer_status: newStatus } : item
                    )
                );
              }
              else if(response.data.status === 'error') {
                setError(response.data.list);
              }
              else {
                setError('Error changing status.');
              }
            } catch (error) {
              setError(error.message);
            }
        }
    }

    return (
        <div className="manage-section">
            {loader && <Loader />}
            {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 

            <div className='lsertbox'>
                <h4>Offers</h4>
            </div>
            <div className='search-box'>
                <input type="text" className="form-control" placeholder="Search" value={search} onChange={handleSearchChange} />
            </div>

            {loading ? (
                <Loading />
            ) : (
                <div className="menu-item-list">
                    <div>
                        {menuList.length > 0 ? (<>
                            {menuList.map((item) => (
                                <div className='ml-box lser-box' key={item.offer_id}>
                                    <div className='ml-header lser-header'>
                                        <div className='lser-ih'>
                                            <div>
                                                <img src={item.offer_image_url ? item.offer_image_url : '/assets/img/icons/default_user.svg'} className='lser-img' alt='' />
                                            </div>
                                            <div>
                                                <h4 className='lser-name mb-0'>{item.offer_name}</h4>
                                                {item.offer_coupon_code ? <p className='coupon_code'>{item.offer_coupon_code}</p> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ml-body lser-body'>
                                        <div className='lserv-btn-box'>
                                            <button className="ml-btn fs-success" onClick={() => openEditModal(item.offer_id)} >
                                                Edit
                                            </button>
                                            <button className="ml-btn fs-danger" onClick={() => openDeleteModal(item.offer_id, item.offer_name)}  >
                                                Delete
                                            </button>
                                        </div>
                                        <div class="toggle">                                                                                   
                                            <input 
                                                type="checkbox" 
                                                name="status" 
                                                id={`ostatus_${item.offer_id}`}
                                                checked={item.offer_status === 1}
                                                onChange={() => changeStatus(item.offer_id, item.offer_status === 1 ? 2 : 1)}
                                            /> 
                                            <label htmlFor={`ostatus_${item.offer_id}`} style={{cursor:'pointer'}}></label>  
                                        </div>
                                    </div>
                                    <div className='ml-footer'>
                                        <p>Created : {item.offer_created_time}</p>
                                        <p>Modified : {item.offer_last_modified_time ? item.offer_last_modified_time : '---'}</p>
                                    </div>
                                </div>
                            ))}
                        </>) : (<>
                            <div className='empty-section'>
                                <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                                <p>No Offers Found</p>
                            </div>
                        </>)}
                    </div>
                </div>
            )}

            {isAddPopup && (
                <div className='popup'>
                    <div className='popup-content mb-5'>
                    <SubscriptionCheck>
                    {loading && <p>Loading...</p>}
                    {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 
                        <div className='cpo-header' style={{ margin: '0' }}>
                            <div>
                                <h4>Add new offer</h4>
                            </div>
                            <span className='popup-close' onClick={() => setIsAddPopup(false)}>&times;</span>
                        </div>
                        <div className='cposb'>
                            <form className="form-section needs-validation" encType="multipart/form-data">
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                    <label htmlFor="offer_img" className="logo-title" style={{ cursor: 'pointer' }}>
                                        {offerImage ? (
                                            <img src={URL.createObjectURL(offerImage)} alt="Profile" className="upload_img" />
                                        ) : (
                                            <img src={'/assets/img/background/menu_banner.png'} alt="Profile" className="upload_img" />
                                        )}
                                        <input 
                                            type="file" 
                                            name="offer_img" 
                                            id="offer_img" 
                                            className="upload d-none" 
                                            onChange={handleOfferImageUpload} 
                                        />
                                        <span className='image__upload_btn'>select image</span>
                                    </label>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <input type="text" id="title" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                            <label htmlFor="title">Title</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <input type="text" id="coupon" className="form-control" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                                            <label htmlFor="coupon">Coupon Code</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <textarea type="text" rows={4} id="description" className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                                            <label htmlFor="description">Description</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <input type="text" id="landingPage" className="form-control" value={landingPage} onChange={(e) => setLandingPage(e.target.value)} />
                                            <label htmlFor="landingPage">Landing Page Link</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='status__box'>
                                            <div>
                                                <label>Status</label>
                                            </div>
                                            <div class="toggle">                                                                                   
                                                <input 
                                                    type="checkbox" 
                                                    name="addStatus" 
                                                    id="addStatus" 
                                                    checked={status === 1}
                                                    onChange={handleStatusChange}
                                                /> 
                                                <label for="addStatus" style={{cursor:'pointer'}}></label>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-12 text-end'>
                                        <button type="button" className="cp-btn" onClick={handleAddData}>SAVE</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </SubscriptionCheck>
                    </div>
                </div>
            )}

            {isDeleteModal && itemId &&(
                <div className='modal-overlay'>
                <div className='modal-content'>
                    <div className='modal-body'>
                    <h6>Delete</h6>
                    <p>Would you like to delete offer with title {itemName} ?</p>
                    </div>
                    <div className='modal-footer'>
                        <button className="close-button" onClick={() => setIsDeleteModal(false)} style={{marginRight:'4rem'}}>No</button>
                        <button className="close-button" onClick={handleItemDelete}>Yes</button>
                    </div>
                </div>
                </div>
            )}

            {isEditModal && (
                <EditOffer
                    itemId={itemId}
                    onClose={handleEditModal}
                />
            )}
            <div>
                <button className='add-data' onClick={() => setIsAddPopup(true)}><i className="fas fa-plus"></i></button>
            </div>
            
        </div>
    );
}

export default Offers;
