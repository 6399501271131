import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import params from '../config/Params';
import SearchOutletPopup from '../components/SearchOutletPopup';
import SelectDataPopup from '../components/SelectDataPopup';
import ImageCropper from '../components/ImageCropper';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loader from '../components/Loader';
import CuisinePopup from '../components/CuisinePopup';
import TagPopup from '../components/TagPopup';
import BusinessTimingPopup from '../components/BusinessTimingPopup';
import Swal from 'sweetalert2';
import GoogleMapComponent from '../components/GoogleMapComponent';
import SubscriptionCheck from '../components/SubscriptionCheck';

function AddListing() {

  const businessListingId = getItemFromLocalStorage('listing_id');

  const [showCropper, setShowCropper] = useState(false);
  const [imagePreview, setImagePreview] = useState('/assets/img/default_logo.svg');
  const [businessName, setBusinessName] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');
  const [address, setAddress] = useState('');
  const [addressDetails, setAddressDetails] = useState('');
  const [fssaiNumber, setFssaiNumber] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessDocument, setBusinessDocument] = useState('');
  const [businessDocumentPreview, setBusinessDocumentPreview] = useState('');
  const [avgCost, setAvgCost] = useState('');
  const [ownerStatus, setOwnerStatus] = useState('');
  const [openingStatus, setOpeningStatusChange] = useState('');
  const [servesAlcohol, setServesAlcohol] = useState('');
  const [services, setServices] = useState('');
  const [seating, setSeating] = useState('');
  const [payment, setPayment] = useState('');
  const [showAlcoholTypes, setShowAlcoholTypes] = useState(false);
  const [alcoholTypes, setAlcoholTypes] = useState([]);
  const [showSeatingTypes, setShowSeatingTypes] = useState(false);
  const [indoorSeating, setIndoorSeating] = useState('');
  const [outdoorSeating, setOutdoorSeating] = useState('');
  const [website, setWebsite] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeType, setActiveType] = useState(null);
  const [activeData, setActiveData] = useState(null);

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [showPopup, setShowPopup] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationId, setLocationId] = useState('');
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [isCruisineModal, setIsCuisineModal] = useState(null);
  const [isTagModal, setIsTagModal] = useState(false);
  const [timingData, setTimingData] = useState([]);
  const [isTimeModal, setIsTimeModal] = useState(false);

  const [showMap, setShowMap] = useState(false);

  const errorRef = useRef(null);

  useEffect(() => {
    if (error && errorRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const handleCloseMap = () => {
    setShowMap(false);
  }

  const handleCrop = (croppedDataUrl) => {
    setImagePreview(croppedDataUrl);
    setShowCropper(false);
  };

  const base64ToBlob = (base64, mime) => {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  };

  const handleSearchOutlet = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setShowPopup(true);
          console.log(position.coords.latitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  }

  const openTimeModal = () => {
    setIsTimeModal(true);
  };

  const closeTimeModal = () => {
    setIsTimeModal(false);
  };

  const handleSelectedTiming = (selectedTiming) => {
    setTimingData(selectedTiming);
    console.log('Selected Timing Data:', selectedTiming);
  };

  const handleSelectItem = (type, item) => {
    if (type === 'category') {
      setSelectedCategories(prevState => {
        if (prevState.some(c => c.category_id === item.category_id)) {
          return prevState.filter(c => c.category_id !== item.category_id);
        } else if (prevState.length < 2) {
          return [...prevState, item];
        } else {
          alert('You can only select up to 2 categories.');
          return prevState;
        }
      });
    } else if (type === 'cuisines') {
      setSelectedCuisines(prevState => {
        if (prevState.some(c => c.cuisine_id === item.cuisine_id)) {
          return prevState.filter(c => c.cuisine_id !== item.cuisine_id);
        } else {
          return [...prevState, item];
        }
      });
    } else if (type === 'tags') {
      setSelectedTags(prevState => {
        if (prevState.some(t => t.tag_id === item.tag_id)) {
          return prevState.filter(t => t.tag_id !== item.tag_id);
        } else {
          return [...prevState, item];
        }
      });
    }
  };

  const handleRemoveItem = (type, itemId) => {
    if (type === 'category') {
      setSelectedCategories(prevState => prevState.filter(c => c.category_id !== itemId));
    } else if (type === 'cuisines') {
      setSelectedCuisines(prevState => prevState.filter(c => c.cuisine_id !== itemId));
    } else if (type === 'tags') {
      setSelectedTags(prevState => prevState.filter(t => t.tag_id !== itemId));
    }
  };

  const handleCuisineSelectedItems = (item) => {
    setSelectedCuisines((prevSelected) =>
      prevSelected.some((cuisine) => cuisine.cuisine_id === item.cuisine_id)
        ? prevSelected.filter((cuisine) => cuisine.cuisine_id !== item.cuisine_id)
        : [...prevSelected, item]
    );
  };

  const handleCuisineRemoveItem = (cuisineId) => {
    setSelectedCuisines((prevSelected) =>
      prevSelected.filter((cuisine) => cuisine.cuisine_id !== cuisineId)
    );
  };

  const openTagModal = () => setIsTagModal(true);
  const closeTagModal = () => setIsTagModal(false);

  const handleSelectTags = (item) => {
    setSelectedTags((prevSelected) =>
      prevSelected.some((tag) => tag.tag_id === item.tag_id)
        ? prevSelected.filter((tag) => tag.tag_id !== item.tag_id)
        : [...prevSelected, item]
    );
  };

  const handleRemoveTags = (tagId) => {
    setSelectedTags((prevSelected) =>
      prevSelected.filter((tag) => tag.tag_id !== tagId)
    );
  };

  const openPopup = (type,typeData) => {
    setActiveType(type);
    setActiveData(typeData);
    setIsPopupOpen(true);
  };

  const handleDone = () => {
    setIsPopupOpen(false);
    setActiveType(null);
    setActiveData(null);
  };

  const openCuisineModal = () => {
    setIsCuisineModal(true);
  }

  const closeCuisineModal = () => {
    setIsCuisineModal(false);
  }

  const transformSelectedCategories = (categories) => {
    return categories.map((category) => category.category_id);
  };

  const transformSelectedCuisines = (cuisines) => {
    return cuisines.map((cuisine) => cuisine.cuisine_id);
  };
  
  const transformSelectedTags = (tags) => {
    return tags.map((tag) => tag.tag_id);
  };

  const handleClaimLocation = async (outlet) => {
    setSelectedOutlet(outlet);
    setBusinessName(outlet.title);
    setAddress(outlet.description);

    let fpData = new FormData();
    fpData.append('country', 'in');
    fpData.append('title', outlet.title);
    fpData.append('description', outlet.description);
    fpData.append('place_id', outlet.id);
    fpData.append('utype', 'captain');

    try {
      const fpResponse = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.findLatLng}`,
        data: fpData
      });

      if (fpResponse.data.status === 'done') {
        console.log(fpResponse.data.lat);
        console.log(fpResponse.data.lng);
        setLatitude(fpResponse.data.lat);
        setLongitude(fpResponse.data.lng);
        setLocationId(fpResponse.data.related_location_id);
      } else if (fpResponse.data.status === 'error') {
        console.error('Error response:', fpResponse.data.list);
      } else {
        console.error('Error in saving data.');
      }
    } catch (err) {
      console.error('An error occurred while saving data:', err);
    }

    setShowPopup(false);
  };

  const handleNotOnList = (outletName) => {
    setBusinessName(outletName);
    setShowPopup(false);
  }

  const handleBusinessDocument = (event) => {
    const fileName = event.target.files[0];
    if (fileName) {
      setBusinessDocument(fileName);
      setBusinessDocumentPreview(fileName.name);
      setError('');
    }
  };

  const handleAlcoholChange = (event) => {
    const value = event.target.value;
    setServesAlcohol(value);
    setShowAlcoholTypes(value === '1');
  };

  const handleAlcoholTypeChange = (value) => {
    setAlcoholTypes((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleServiceChange = (value) => {
    setServices((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleSeatingChange = (event) => {
    const value = event.target.value;
    setSeating(value);
    setShowSeatingTypes(value === '1');
  }

  const handleIndoorSeating = (e) => {
    setIndoorSeating(parseInt(e.target.value, 10));
  }

  const handleOutdoorSeating = (e) => {
    setOutdoorSeating(parseInt(e.target.value, 10));
  }

  const handlePaymentChange = (event) => {
    setPayment(event.target.value);
  }

  useEffect(() => {
    const captainProfile = getItemFromLocalStorage('captainProfile');
    if (captainProfile) {
      if(captainProfile.captain_access_token) {
        setToken(captainProfile.captain_access_token);
      }
      if(captainProfile.business_contact_number) {
        setNumber(captainProfile.business_contact_number);
      }
    }
  }, []);

  function handleOwnerStatusChange(e) {
    setOwnerStatus(e.target.value);
  }

  function handleOpeningStatusChange(e) {
    setOpeningStatusChange(e.target.value);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedCategories.length === 0) {
      setError('Please select at least one category.');
      return;
    }
    if (!businessDocument) {
      setError('Please upload a business license.');
      return;
    }
    if (imagePreview === '/assets/img/default_logo.svg') {
      setError('Please upload a business profile.');
      return;
    }

    const blob = base64ToBlob(imagePreview, 'image/jpg');

    const transformedCategories = transformSelectedCategories(selectedCategories);
    const transformedCuisine = transformSelectedCuisines(selectedCuisines);
    const transformedTags = transformSelectedTags(selectedTags);

    let formData = new FormData();
    formData.append('token', token);
    formData.append('business_type', 'Business');
    formData.append('business_name', businessName);
    formData.append('business_city', city);
    formData.append('business_phone', number);
    formData.append('business_address', address);
    formData.append('business_address1', addressDetails);
    formData.append('fssai_no', fssaiNumber);
    formData.append('business_email', businessEmail);
    formData.append('avg_cost_for_two', avgCost);
    formData.append('business_owner', ownerStatus);
    formData.append('business_opening_status', openingStatus);
    formData.append('business_latitude', latitude);
    formData.append('business_longitude', longitude);
    formData.append('related_location_id', locationId);
    formData.append('business_serves_alcohol', servesAlcohol);
    formData.append('business_serves_alcohol_types', alcoholTypes);
    formData.append('business_services', services);
    formData.append('business_category', transformedCategories);
    formData.append('business_cuisines', transformedCuisine);
    formData.append('business_tags', transformedTags);
    formData.append('business_seating', seating);
    formData.append('business_seating_indoor', indoorSeating);
    formData.append('business_seating_outdoor', outdoorSeating);
    formData.append('business_payment', payment);
    formData.append('business_website', website);
    formData.append('timings', JSON.stringify(timingData));
    if (blob) {
      formData.append('entry_pic', blob);
    }
    if (businessDocument) {
      formData.append('business_document_pdf', businessDocument);
    }

    setLoading(true);

    try {
      const response = await axios({
        method: 'post',
        headers: { 
          'Content-Type': 'multipart/form-data' 
        },
        url: `${params.baseURL}${params.endpoints.saveBusiness}`,
        data: formData
      });

      if (response.data.status === 'new') {
        if(businessListingId === null){
          sessionStorage.setItem('listing_id', response.data.registered_listing_id);
        }
        Swal.fire({
          icon: 'success',
          text: 'New business listing created successfully!',
          confirmButtonText: 'OK'
          }).then((result) => {
          if (result.isConfirmed) {
              window.location.href = '/manage-listing';
          }
        });
      } else if (response.data.status === 'error') {
        setError(response.data.list);
      } else {
        setError('Error in saving data.');
      }
    } catch (err) {
      setError('An error occurred while saving data.');
    }finally {
      setLoading(false);
    } 
  }

  return (
    <div>
      <SubscriptionCheck >
      <h4 className="form-title">Claim Your Location</h4>
      {loading && <Loader />}
      {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 
      <form className="form-section needs-validation" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="row g-3">
            <div className="os-section">
              <div className="os-box">
                <button type='button' className='search-outlet-btn' onClick={handleSearchOutlet}>{businessName ? businessName : (selectedOutlet ? selectedOutlet.title : 'Search Outlet')}</button>
                {showPopup && (
                  <SearchOutletPopup
                    latitude={latitude}
                    longitude={longitude}
                    onClose={handleClosePopup}
                    onClaimLocation={handleClaimLocation}
                    onNotOnList={handleNotOnList}
                  />
                )}
              </div>
              <div className="logo-box">
                <label htmlFor="businessLogo" className="logo-title" onClick={() => setShowCropper(true)}>
                  <img src={imagePreview} alt="Profile" className="rounded-circle" />
                  <span className='d-block'>Upload Outlet Logo</span>
                </label>
                {showCropper && (
                  <ImageCropper onCrop={handleCrop} onClose={() => setShowCropper(false)} />
                )}
              </div>
            </div>

            <div className="col-md-6">
                <div className="form-floating">
                    <input type="text" id="city" className="form-control" value={city} onChange={(e) => setCity(e.target.value)} required />
                    <label htmlFor="city">City</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating">
                    <input type="text" id="number" className="form-control" value={number} onChange={(e) => setNumber(e.target.value)} required />
                    <label htmlFor="number">Phone Number</label>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-floating">
                    <input style={{paddingRight:'40px'}} type="text" id="address" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} required />
                    <label htmlFor="address">Address/Landmark</label>
                    <button type='button' className='location-icon' onClick={handleLocationClick}>
                      <img src={'/assets/img/icons/location_marker.svg'} alt='' />
                    </button> 
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-floating">
                    <input type="text" id="addressDetails" className="form-control" value={addressDetails} onChange={(e) => setAddressDetails(e.target.value)} />
                    <label htmlFor="addressDetails">Address Details, If Any</label>
                </div>
            </div>
        </div>
        <span className='separator'></span>
        <h2 className="form-title">Business Information</h2>
        <div className="row g-3">
            <div className="col-md-12">
                <div className="form-floating">
                    <input type="text" id="fssaiNumber" className="form-control" value={fssaiNumber} onChange={(e) => setFssaiNumber(e.target.value)} required />
                    <label htmlFor="fssaiNumber">Fssai Number (Required)</label>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-floating">
                    <input type="text" id="businessEmail" className="form-control" value={businessEmail} onChange={(e) => setBusinessEmail(e.target.value)} required />
                    <label htmlFor="businessEmail">Email</label>
                </div>
            </div>
            <div className="col-md-12">
                <legend className="col-form-label">Are You The Owner Of This Place ?</legend>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="ownerStatus" id="owner" value="1" checked={ownerStatus === '1'} onChange={handleOwnerStatusChange} />
                    <label className="form-check-label" htmlFor="owner">
                        I'm the owner/Manager
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="ownerStatus" id="notOwner" value="2" checked={ownerStatus === '2'} onChange={handleOwnerStatusChange} />
                    <label className="form-check-label" htmlFor="notOwner">
                        I'm not the owner/Manager
                    </label>
                </div>
            </div>
            <div className="col-md-12">
            <   legend className="col-form-label">Opening Status ?</legend>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="openingStatus" id="opened" value="1" checked={openingStatus === '1'} onChange={handleOpeningStatusChange} />
                    <label className="form-check-label" htmlFor="opened">
                        This Place Is Already Open
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="openingStatus" id="notOpened" value="2" checked={openingStatus === '2'} onChange={handleOpeningStatusChange} />
                    <label className="form-check-label" htmlFor="notOpened">
                        This Place Is  Opening Soon
                    </label>
                </div>
            </div>
            <div className="col-md-12 mt-4">
                <div className="form-floating">
                    <input type="text" id="avgCost" className="form-control" value={avgCost} onChange={(e) => setAvgCost(e.target.value)} required />
                    <label htmlFor="avgCost">Average Cost For Two Person</label>
                </div>
            </div>

            <div className="col-md-12">
                <div className='checkPop'>
                  <button type='button' className='select-btn' onClick={() => openPopup('category','category')}>Select Category (Required)</button>
                  <SelectDataPopup
                    isOpen={isPopupOpen}
                    onClose={handleDone}
                    onSelect={handleSelectItem}
                    onDone={handleDone}
                    selectedItems={selectedCategories}
                    activeType={activeType}
                    activeData={activeData}
                  />
                </div>
                <div className='sdcb'>
                  {selectedCategories.map(category => (
                    <div className='sdlb' key={category.category_id}>
                      {category.category_name}
                      <span className='rdflb' onClick={() => handleRemoveItem('category', category.category_id)}>
                        X
                      </span>
                    </div>
                  ))}
                </div>
            </div>

            <div className='col-md-12'>
              <div className='checkPop'>
                <button type='button' className='select-btn' onClick={() => openCuisineModal()}>Select Cuisine</button>
                <CuisinePopup
                  isOpen={isCruisineModal}
                  onClose={closeCuisineModal}
                  onSelect={handleCuisineSelectedItems}
                  onDone={closeCuisineModal}
                  selectedItems={selectedCuisines}
                />
              </div>
              <div className='sdcb'>
                {selectedCuisines.map(cuisine => (
                  <div className='sdlb' key={cuisine.cuisine_id}>
                    {cuisine.cuisine_name}
                    <span className='rdflb' onClick={() => handleCuisineRemoveItem(cuisine.cuisine_id)}>
                      X
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className='col-md-12'>
              <div className='checkPop'>
                <button type='button' className='select-btn' onClick={openTagModal}>Select Tags</button>
                <TagPopup
                  isOpen={isTagModal}
                  onClose={closeTagModal}
                  onSelect={handleSelectTags}
                  onDone={closeTagModal}
                  selectedItems={selectedTags}
                />
              </div>
              <div className='sdcb'>
                {selectedTags.map(tag => (
                  <div className='sdlb' key={tag.tag_id}>
                    {tag.tag_name}
                    <span className='rdflb' onClick={() => handleRemoveTags(tag.tag_id)}>
                      X
                    </span>
                  </div>
                ))}
              </div>
            </div>

        </div>
        <span className='separator'></span>
        <div className="hint-section">
            <h6 className='hint-title'>Characteristics</h6>
            <p className='hint-text'>Please Note - You Must Carefully Choose the Characteristics of Your Outlet as this will impact your customer acquisition.</p>
        </div>
        <div className='row g-3'>
            <div className="col-md-12 char-lists">
              <legend className="col-form-label">Alcohol</legend>
              <div className="form-check">
                  <input className="form-check-input" type="radio" name="servesAlcohol" id="servesAlcohol" value="1" checked={servesAlcohol === '1'} onChange={handleAlcoholChange} />
                  <label className="form-check-label" htmlFor="servesAlcohol">
                      Serves Alcohol
                  </label>
              </div>
              <div className="form-check">
                  <input className="form-check-input" type="radio" name="servesAlcohol" id="notServesAlcohol" value="2" checked={servesAlcohol === '2'} onChange={handleAlcoholChange} />
                  <label className="form-check-label" htmlFor="notServesAlcohol">
                      Does't Serves Alcohol
                  </label>
              </div>
            </div>
            {showAlcoholTypes && (
              <div className="col-md-12 char-lists">
                {[
                  { label: 'Wine', value: '1' },
                  { label: 'Beer', value: '2' },
                  { label: 'Full Bar', value: '3' },
                  { label: 'Serves Cocktails', value: '4' }
                ].map((type) => (
                  <div className="form-check" key={type.value}>
                    <input 
                      className="form-check-input" 
                      type="checkbox" 
                      id={type.label.toLowerCase().replace(' ', '')} 
                      value={type.value} 
                      checked={alcoholTypes.includes(type.value)} 
                      onChange={() => handleAlcoholTypeChange(type.value)} 
                    />
                    <label className="form-check-label" htmlFor={type.label.toLowerCase().replace(' ', '')}>
                      {type.label}
                    </label>
                  </div>
                ))}
              </div>
            )}

            <div className="col-md-12 char-lists">
              <legend className="col-form-label">Services</legend>
              {[
                { label: 'Breakfast', value: '1' },
                { label: 'Lunch', value: '2' },
                { label: 'Dinner', value: '3' },
                { label: 'Cafe', value: '4' },
                { label: 'Nightlife', value: '5' }
              ].map((type) => (
                <div className="form-check" key={type.value}>
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id={type.label.toLowerCase().replace(' ', '')} 
                    value={type.value} 
                    checked={services.includes(type.value)} 
                    onChange={() => handleServiceChange(type.value)} 
                  />
                  <label className="form-check-label" htmlFor={type.label.toLowerCase().replace(' ', '')}>
                    {type.label}
                  </label>
                </div>
              ))}
            </div>

            <div className="col-md-12 char-lists">
              <legend className="col-form-label">Seating</legend>
              <div className="form-check">
                  <input className="form-check-input" type="radio" name="seating" id="seatingAvailable" value="1" checked={seating === '1'} onChange={handleSeatingChange} />
                  <label className="form-check-label" htmlFor="seatingAvailable">
                      Seating Available
                  </label>
              </div>
              <div className="form-check">
                  <input className="form-check-input" type="radio" name="seating" id="seatingNotAvailable" value="2" checked={seating === '2'} onChange={handleSeatingChange} />
                  <label className="form-check-label" htmlFor="seatingNotAvailable">
                      No Seating Available
                  </label>
              </div>
            </div>
            {showSeatingTypes && (
              <div className="col-md-12 char-lists">
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id='indoor'
                    value='1'
                    checked={indoorSeating === 1}
                    onChange={handleIndoorSeating} 
                  />
                  <label className="form-check-label" htmlFor='indoor'>
                    Indoor
                  </label>
                </div>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id='outdoor'
                    value='1'
                    checked={outdoorSeating === 1}
                    onChange={handleOutdoorSeating} 
                  />
                  <label className="form-check-label" htmlFor='outdoor'>
                    outdoor
                  </label>
                </div>
              </div>
            )}

            <div className="col-md-12 char-lists">
              <legend className="col-form-label">Payment</legend>
              <div className="form-check">
                  <input className="form-check-input" type="radio" name="payment" id="paymentOnline" value="1" checked={payment === '1'} onChange={handlePaymentChange} />
                  <label className="form-check-label" htmlFor="paymentOnline">
                      Cards and UPI
                  </label>
              </div>
              <div className="form-check">
                  <input className="form-check-input" type="radio" name="payment" id="paymentCash" value="2" checked={payment === '2'} onChange={handlePaymentChange} />
                  <label className="form-check-label" htmlFor="paymentCash">
                      Cash
                  </label>
              </div>
            </div>

            <div className='col-md-12'>
              <div className='checkPop'>
                <button type='button' className='select-btn' onClick={() => openTimeModal()}>Update Timings</button>
                <BusinessTimingPopup
                  isOpen={isTimeModal}
                  onClose={closeTimeModal}
                  onSelect={handleSelectedTiming}
                  onDone={closeTimeModal}
                  storedData={null}
                />
              </div>
            </div>

            <div className="col-md-12">
                <div className="form-floating">
                    <input type="text" id="website" className="form-control" value={website} onChange={(e) => setWebsite(e.target.value)} />
                    <label htmlFor="website">Website</label>
                </div>
                <span className='list-hint'>Optional</span>
            </div>

        </div>
        <span className='separator'></span>
        <h2 className="form-title">Attach Business License</h2>
        <div className="row g-3">
            <div className="col-md-12">
                <div className="doc-box">
                  <label htmlFor="businessDocument" className="bd-label">
                    <img src={'assets/img/pdf_icon.png'} alt="Profile" className="img-pdf" />
                    <input
                      id="businessDocument"
                      className="form-control d-none"
                      type="file"
                      name="business_document_pdf"
                      accept="application/pdf"
                      onChange={handleBusinessDocument}
                    />
                  </label>
                  <div id="uploadedFileName">{businessDocumentPreview}</div>
                </div>
            </div>
        </div>

        <div className='row mt-4'>
            <div className='col-md-12 text-end'>
                <button type="submit" className="btn btn-success w-100">ADD BUSINESS</button>
            </div>
        </div>

      </form>
      {showMap && (
        <div>
          <GoogleMapComponent 
            setAddress={setAddress} 
            setLatitude={setLatitude} 
            setLongitude={setLongitude}
            onClose={handleCloseMap}
          />
        </div>
      )}
      </SubscriptionCheck>
    </div>
  );
}

export default AddListing;
