import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import CommentPopup from './CommentPopup';
import Loading from './Loading';

const VideoItemsPopup = ({ images, businessName, profileImage, sharingText, sharingUrl, onClose }) => {
  const captainData = getItemFromLocalStorage('captainProfile');
  const token = captainData?.captain_access_token;
  const businessListingId = getItemFromLocalStorage('listing_id');
  const [likeList, setLikeList] = useState([]);
  const [isLikeListModal, setIsLikeListModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [photoData, setPhotoData] = useState(images.map(img => ({
    ...img,
    comments: img.comments,
    liked: img.liked === 1,
    likes: img.likes
  })));
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  useEffect(() => {
    setPhotoData(images.map(img => ({
      ...img,
      comments: img.comments,
      liked: img.liked === 1,
      likes: img.likes
    })));
  }, [images]);

  const handleLikeClick = async (photoId, photoItemId) => {
    if (token) {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('photo_id', photoId);
      formData.append('photo_item_id', photoItemId);
      formData.append('listing_id', businessListingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.saveLike}`,
          data: formData
        });

        if (response.data.status === 'saved' || response.data.status === 'removed') {
          setPhotoData(prevPhotoData => prevPhotoData.map(photo => {
            if (photo.photo_id === photoId && photo.photo_item_id === photoItemId) {
              const liked = response.data.status === 'saved';
              const likes = liked ? photo.liked + 1 : photo.liked - 1;
              return { ...photo, liked, likes: Number(likes) };
            }
            return photo;
          }));
        }
      } catch (error) {
        console.error('Error saving like:', error.message);
      }
    }
  };

  const handleCommentClick = (photoId, photoItemId) => {
    setSelectedPhoto({ photoId, photoItemId });
    setShowCommentPopup(true);
  };

  const handleSharePost = () => {
    console.log(sharingText, sharingUrl);
    if (navigator.share) {
      navigator.share({
        title: sharingText || 'Default Title',
        url: sharingUrl || window.location.href,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  const openLikeListModal = async (photoId, photoItemId) => {
    setIsLikeListModal(true);

    if (token) {
      setLoading(true);
      const formData = new FormData();
      formData.append('token', token);
      formData.append('photo_id', photoId);
      formData.append('photo_item_id', photoItemId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.likeList}`,
          data: formData
        });
        if (response.data.items && Array.isArray(response.data.items)) {
          setLikeList(response.data.items);
          setIsLikeListModal(true);
        }
        else {
          console.log('Like List not found');
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <div className='lcp-header'>
          <div className='piphs'>
            <img src={profileImage} alt='' />
            <div>
              <h4>{businessName}</h4>
              <span>{businessName}</span>
            </div>
          </div>
          <span className='popup-close' onClick={onClose}>&times;</span>
        </div>
        <div className="popup-images">
          {photoData.map(img => (
            <div style={{position:'relative'}} key={img.photo_item_id}>
              <video src={img.video_url} alt="" autoPlay />
              <div className='video-profile-section'>
                <div className='vstxtbox'>
                    <img src={profileImage} alt='' />
                    <div className='video-profile-txt'>
                        <span>{businessName}</span>
                        <span>{businessName}</span>
                    </div>
                </div>
                <div>

                </div>
              </div>
              <div className="lcs-box lcvsb">
                <div className='mb-4'>
                  <img 
                    src={img.liked ? '/assets/img/icons/white_like_fill.svg' : '/assets/img/icons/white_like_outline.svg'}
                    alt="" 
                    onClick={() => handleLikeClick(img.photo_id, img.photo_item_id)} 
                  />
                  <div style={{cursor: 'pointer'}} onClick={() => openLikeListModal(img.photo_id, img.photo_item_id)}>{img.likes}</div>
                </div>
                <div className='mb-4'>
                  <img 
                    src={'/assets/img/icons/white_comment.svg'} 
                    alt="" 
                    onClick={() => handleCommentClick(img.photo_id, img.photo_item_id)} 
                  />
                  <div style={{cursor:'pointer'}} onClick={() => handleCommentClick(img.photo_id, img.photo_item_id)} >{img.comments}</div>
                </div>
                <div>
                  <img 
                    src={'/assets/img/icons/white_share.svg'} 
                    alt="" 
                    onClick={() => handleSharePost()} 
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isLikeListModal && (
        <div className='popup'>
          <div className='popup-content'>
            {loading ? (
              <Loading />
            ) : (
              <div>
              {likeList.length > 0 ? (
                <>
                  <div className='lcp-header'>
                    <div>
                      <h4>Likes</h4>
                      <p>for {businessName}</p>
                    </div>
                    <span className='popup-close' onClick={() => setIsLikeListModal(false)}>&times;</span>
                  </div>
                  <div>
                    {likeList.map(list => (
                      <div className='lculb' key={list.photo_like_id}>
                        <div className='lcirts'>
                          <div className='lcuib'>
                            <img className='lcllu-img' src={list.owner_info.image} alt='Live Crowd' />
                          </div>
                          <div className='lcucb'>
                            <p className='lcuc-title'>{list.owner_info.title}</p>
                            <p className='lcuc-time'>{list.created_time_as_text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div>
                  <div className='lcp-header'>
                    <div>
                      <h4>Likes</h4>
                      <p>for {businessName}</p>
                    </div>
                    <span className='popup-close' onClick={() => setIsLikeListModal(false)}>&times;</span>
                  </div>
                  <div className='empty-section'>
                    <p>No Likes Receives Yet!</p>
                  </div>
                </div>
              )}
              </div>
            )}
          </div>
        </div>
      )}

      {showCommentPopup && selectedPhoto && (
        <CommentPopup 
          photoId={selectedPhoto.photoId}
          photoItemId={selectedPhoto.photoItemId}
          onClose={() => setShowCommentPopup(false)}
        />
      )}
    </div>
  );
};

export default VideoItemsPopup;
