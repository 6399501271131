import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';
import { toast } from 'react-toastify';
import CommentRepliesPopup from './CommentRepliesPopup';

const CommentPopup = ({ photoId, photoItemId, onClose }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isEditComment, setIsEditComment] = useState(false);
  const [isCommentDeleteModal, setIsCommentDeleteModal] = useState(false);
  const [commentId, setcommentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [showReplyPopup, setShowReplyPopup] = useState(false);

  const captainData = getItemFromLocalStorage('captainProfile');
  const token = captainData?.captain_access_token;
  const businessListingId = getItemFromLocalStorage('listing_id');

  const fetchComments = useCallback(async () => {
    if (token) {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('photo_id', photoId);
      formData.append('photo_item_id', photoItemId);
      formData.append('listing_id', businessListingId);
      formData.append('utype', 'captain');

      setLoading(true);
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.commentList}`,
          data: formData
        });

        if (response.data.items && Array.isArray(response.data.items)) {
          setComments(response.data.items);
        }
      } catch (error) {
        console.error('Error saving like:', error.message);
      } finally {
        setLoading(false);
      }
    }
  }, [businessListingId, token, photoId, photoItemId]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleEditComment = async (commentId, comment) => {
    setcommentId(commentId);
    setNewComment(comment);
    setIsEditComment(true);
  };

  const handleEditCommentClose = async() => {
    setcommentId('');
    setNewComment('');
    setIsEditComment(false);
  }

  const handleAddComment = async () => {
    if (newComment.trim() === '') return;

    const formData = new FormData();
    formData.append('token', token);
    formData.append('photo_id', photoId);
    formData.append('photo_item_id', photoItemId);
    formData.append('listing_id', businessListingId);
    formData.append('comment', newComment);
    formData.append('utype', 'captain');

    if(commentId) {
      formData.append('comment_id', commentId);
    }

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.saveComment}`,
        data: formData
      });

      if (response.data.status === 'saved' || response.data.status === 'updated') {
        setNewComment('');
        setcommentId('');
        fetchComments();
        setIsEditComment(false);
      }
    } catch (error) {
      console.error('Error saving comment:', error.message);
    }
  };

  const openDeleteModal = async (commentId) => {
    setcommentId(commentId);
    setIsCommentDeleteModal(true);
  };

  const handleCommentDelete = async () => {
    if (token) {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('photo_id', photoId);
      formData.append('comment_id', commentId);
      formData.append('listing_id', businessListingId);
      formData.append('utype', 'captain');

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.deleteComment}`,
          data: formData
        });
        if (response.data.status === 'deleted') {
          fetchComments();
          setIsCommentDeleteModal(false)
        }
        else if(response.data.status === 'error') {
          toast.error(response.data.list);
          setIsCommentDeleteModal(false)
        }
        else {
          toast.error('Error Deleting Comment.');
          setIsCommentDeleteModal(false)
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const handleRelyClick = (photoCommentId) => {
    setSelectedComment({ photoCommentId });
    setShowReplyPopup(true);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <div className='cpo-header' style={{ margin: '0' }}>
            <div>
                <h4>Comments</h4>
            </div>
            <span className='popup-close' onClick={onClose}>&times;</span>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="comments-list">
          {comments.length > 0 ? (<>
            {comments.map(comment => (
              <div key={comment.photo_comment_id} className="comment-item">
                <div className='comment-detail-box'>
                  <img src={comment.owner_info.image} alt='' className='comment-person-profile' />
                  <div className='comment-ta'>
                      <div className='comment-text'>
                          <p>{comment.owner_info.title}</p>
                          <p style={{fontSize:'12px'}}>{comment.comment}</p>
                      </div>
                      <div className='comment-action'>
                          <p style={{color:'gray',fontSize:'12px',minWidth:'200px'}}>{comment.created_time_as_text}</p>
                          <button onClick={() => handleRelyClick(comment.photo_comment_id)}>{comment.total_replied} Reply</button>
                          { comment.is_it_my_comment === true &&(
                            <button onClick={() => handleEditComment(comment.photo_comment_id, comment.comment)}>Edit</button>
                          )}
                          <button onClick={() => openDeleteModal(comment.photo_comment_id)}>Remove</button>
                      </div>
                  </div>
                </div>
              </div>
            ))}
          </>) : (<>
              <div className='empty-comment'>
                  <p>No comments</p>
              </div>
          </>)}
          </div>
        )}
        {isEditComment && commentId &&(
          <div className='edit-comment'>
            <p>Edit comment</p>
            <span className='ec-close' onClick={handleEditCommentClose}>&times;</span>
          </div>
        )}
        <div className="add-comment">
            <div className="comment-form">
                <input className="form-control" type="text" placeholder="write your comment" 
                    value={newComment} 
                    onChange={(e) => setNewComment(e.target.value)} 
                />
                <button className='add-comment-btn' onClick={handleAddComment}><i class="far fa-paper-plane"></i></button>
            </div>
        </div>

        {isCommentDeleteModal && commentId &&(
          <div className='modal-overlay'>
            <div className='modal-content'>
              <div className='modal-body'>
                <h6>Confirm!</h6>
                <p>Would you like to remove selected comment?</p>
              </div>
              <div className='modal-footer'>
                  <button className="close-button" onClick={() => setIsCommentDeleteModal(false)} style={{marginRight:'4rem'}}>No</button>
                  <button className="close-button" onClick={handleCommentDelete}>Yes</button>
              </div>
            </div>
          </div>
        )}

        {showReplyPopup && selectedComment && (
          <CommentRepliesPopup
            photoCommentId={selectedComment.photoCommentId}
            onClose={() => setShowReplyPopup(false)}
          />
        )}

      </div>
    </div>
  );
};

export default CommentPopup;
