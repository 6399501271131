import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import SubscriptionCheck from '../components/SubscriptionCheck';
import ExtraDataPopup from '../components/ExtraDataPopup';
import EditPushNotification from './EditPushNotification';
import ExtraNotificationPopup from '../components/ExtraNotificationPopup';
import AttachOffer from './AttachOffer';

const daysOfWeek = [
    { day_id: 1, day_name: 'Monday', short_name: 'Mo' },
    { day_id: 2, day_name: 'Tuesday', short_name: 'Tu' },
    { day_id: 3, day_name: 'Wednesday', short_name: 'We' },
    { day_id: 4, day_name: 'Thursday', short_name: 'Th' },
    { day_id: 5, day_name: 'Friday', short_name: 'Fr' },
    { day_id: 6, day_name: 'Saturday', short_name: 'Sa' },
    { day_id: 7, day_name: 'Sunday', short_name: 'Su' },
];

function PushNotifications() {

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    const [menuList, setMenuList] = useState([]);
    const [search, setSearch] = useState('');
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [scheduledDateList, setScheduledDateList] = useState([]);
    const [scheduledTimeList, setScheduledTimeList] = useState([]);
    const [scheduledDate, setScheduledDate] = useState('');
    const [scheduledTime, setScheduledTime] = useState('');
    const [genderList, setGenderList] = useState([]);
    const [gender, setGender] = useState([]);
    const [maritalList, setMaritalList] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [minAgeList, setMinAgeList] = useState([]);
    const [maxAgeList, setMaxAgeList] = useState([]);
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [locationTypeList, setLocationTypeList] = useState([]);
    const [locationType, setLocationType] = useState(0);
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState(0);
    const [StateList, setStateList] = useState([]);
    const [state, setState] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState(0);
    const [selectedExtraData, setSelectedExtraData] = useState([]);
    const [selectedCollegeData, setSelectedCollegeData] = useState([]);
    const [selectedSchoolData, setSelectedSchoolData] = useState([]);
    const [selectedHometownData, setSelectedHometownData] = useState([]);
    const [selectedCurrentCity, setSelectedCurrentCity] = useState([]);
    const [status, setStatus] = useState(0);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isImageValid, setIsImageValid] = useState(true);
    const [itemId, setItemId] = useState('');
    const [itemName, setItemName] = useState('');
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [extraDataModel, setExtraDataModal] = useState(false);
    const [collegeDataModel, setCollegeDataModal] = useState(false);
    const [schoolDataModel, setSchoolDataModel] = useState(false);
    const [hometownDataModel, setHometownDataModel] = useState(false);
    const [currentcityDataModel, setCurrentCityModel] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [offerId, setOfferId] = useState('');
    const [totalAudience, setTotalAudience] = useState('');
    const [totalAudienceNo, setTotalAudienceNo] = useState('');

    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [isAddPopup, setIsAddPopup] = useState(false);
    const [showOfferPopup, setShowOfferPopup] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);

    const errorRef = useRef(null);

    useEffect(() => {
        if (error && errorRef.current) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error]);

    const fetchMenuList = useCallback(async () => {
        if (businessListingId && token) {
            setLoading(true);
            const formData = new FormData();
            formData.append('listing_id', businessListingId);
            formData.append('token', token);
            if (search) {
                formData.append('keyword', search);
            }

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.pushEntryList}`,
                    data: formData
                });
                if (response.data.items && Array.isArray(response.data.items)) {
                    setMenuList(response.data.items);
                } else {
                    console.log('Push Notifications not found.');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    }, [businessListingId, token, search]);

    useEffect(() => {
        fetchMenuList();
    }, [fetchMenuList]);

    useEffect(() => {
        const fetchBaseData = async () => {
            if (isAddPopup && businessListingId && token) {
                const formData = new FormData();
                formData.append('listing_id', businessListingId);
                formData.append('token', token);

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.pushEntryBaseData}`,
                        data: formData
                    });
                    if (response.data.status === 'ok') {
                        setScheduledDateList(response.data.date_list);
                        setScheduledTimeList(response.data.time_list);
                        setGenderList(response.data.gender_list);
                        setMaritalList(response.data.marital_status_list);
                        setMinAgeList(response.data.age_min_list);
                        setMaxAgeList(response.data.age_max_list);
                        setLocationTypeList(response.data.location_options);
                        setCountryList(response.data.country_list);
                        setStateList(response.data.state_list);
                        setCityList(response.data.city_list);
                    } else {
                        console.log('Base data not found.');
                    }
                } catch (error) {
                    setError(error.message);
                }
            }
        };

        fetchBaseData();
    }, [isAddPopup, businessListingId, token]);


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
            img.src = objectUrl;

            img.onload = () => {
                if (img.width === 800 && img.height === 320) {
                    setImage(file);
                    setIsImageValid(true);
                } else {
                    toast.error('Image dimensions should be 800px x 320px.');
                    setImage('');
                    setIsImageValid(false);
                }
                URL.revokeObjectURL(objectUrl);
            };
        }
    };

    const handleTimeFromChange = (newValue) => {
        setTimeFrom(newValue);
    };

    const handleTimeToChange = (newValue) => {
        setTimeTo(newValue);
    };

    const handleCountryChange = async (e) => {
        setCountry(e.target.value);

        const formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('mode', 'add');
        formData.append('country_id', e.target.value);

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.getStateList}`,
                data: formData
            });
            if (response.data.status === 'ok') {
                setStateList(response.data.state_list);
                setState('');
            } else {
                console.log('data not found.');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleStateChange = async (e) => {
        setState(e.target.value);

        const formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('mode', 'add');
        formData.append('country_id', country);
        formData.append('state_id', e.target.value);

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.getCityList}`,
                data: formData
            });
            if (response.data.status === 'ok') {
                setCityList(response.data.city_list);
                setCity('');
            } else {
                console.log('data not found.');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleCityChange = async (e) => {
        setCity(e.target.value);
    }

    const handleScheduledDate = async (e) => {
        setScheduledDate(e.target.value);

        const formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('mode', 'add');
        formData.append('date', e.target.value);

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.getTimeList}`,
                data: formData
            });
            if (response.data.status === 'ok') {
                setScheduledTimeList(response.data.time_list);
                setScheduledTime('');
            } else {
                console.log('data not found.');
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleScheduledTime = (e) => {
        setScheduledTime(e.target.value);
    }

    const handleMinAgeChange = (e) => {
        setMinAge(e.target.value);
    };

    const handleMaxAgeChange = (e) => {
        setMaxAge(e.target.value);
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.checked ? 1 : 2);
    };

    const handleLocationTypeChange = (e) => {
        const value = e.target.value;
        setLocationType(value);
    }

    const openExtraDataPopup = () => {
        setExtraDataModal(true);
    }
    
    const closeExtraDataModal = () => {
        setExtraDataModal(false);
    }

    const openCollegeDataPopup = () => {
        setCollegeDataModal(true);
    }
    
    const closeCollegeDataModal = () => {
        setCollegeDataModal(false);
    }

    const openSchoolDataPopup = () => {
        setSchoolDataModel(true);
    }
    
    const closeSchoolDataModal = () => {
        setSchoolDataModel(false);
    }

    const openHometownDataModel = () => {
        setHometownDataModel(true);
    }
    
    const closeHometownDataModel = () => {
        setHometownDataModel(false);
    }

    const openCurrentCityDataModel = () => {
        setCurrentCityModel(true);
    }
    
    const closeCurrentCityDataModel = () => {
        setCurrentCityModel(false);
    }

    const handleExtraDataSelectedItems = (item) => {
        setSelectedExtraData((prevSelected) =>
          prevSelected.some((data) => data.data_id === item.data_id)
            ? prevSelected.filter((data) => data.data_id !== item.data_id)
            : [...prevSelected, item]
        );
    };
    
    const handleExtraDataRemoveItem = (dataId) => {
        setSelectedExtraData((prevSelected) =>
          prevSelected.filter((data) => data.data_id !== dataId)
        );
    };

    const handleCollegeDataSelectedItems = (item) => {
        setSelectedCollegeData((prevSelected) =>
          prevSelected.some((data) => data.data_id === item.data_id)
            ? prevSelected.filter((data) => data.data_id !== item.data_id)
            : [...prevSelected, item]
        );
    };
    
    const handleCollegeDataRemoveItem = (dataId) => {
        setSelectedCollegeData((prevSelected) =>
          prevSelected.filter((data) => data.data_id !== dataId)
        );
    };

    const handleSchoolDataSelectedItems = (item) => {
        setSelectedSchoolData((prevSelected) =>
          prevSelected.some((data) => data.data_id === item.data_id)
            ? prevSelected.filter((data) => data.data_id !== item.data_id)
            : [...prevSelected, item]
        );
    };
    
    const handleSchoolDataRemoveItem = (dataId) => {
        setSelectedSchoolData((prevSelected) =>
          prevSelected.filter((data) => data.data_id !== dataId)
        );
    };

    const handleHometownDataSelectedItems = (item) => {
        setSelectedHometownData((prevSelected) =>
          prevSelected.some((data) => data.data_id === item.data_id)
            ? prevSelected.filter((data) => data.data_id !== item.data_id)
            : [...prevSelected, item]
        );
    };
    
    const handleHometownDataRemoveItem = (dataId) => {
        setSelectedHometownData((prevSelected) =>
          prevSelected.filter((data) => data.data_id !== dataId)
        );
    };

    const handleCurrentCitySelectedItems = (item) => {
        setSelectedCurrentCity((prevSelected) =>
          prevSelected.some((data) => data.data_id === item.data_id)
            ? prevSelected.filter((data) => data.data_id !== item.data_id)
            : [...prevSelected, item]
        );
    };
    
    const handleCurrentCityDataRemoveItem = (dataId) => {
        setSelectedCurrentCity((prevSelected) =>
          prevSelected.filter((data) => data.data_id !== dataId)
        );
    };

    const transformSelectedExtraData = (items) => {
        return items.map((data) => data.data_id);
    };

    const handleGenderChange = (event) => {
        const { value, checked } = event.target;
        setGender((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((id) => id !== value)
        );
    };

    const handleMaritalChange = (event) => {
        const { value, checked } = event.target;
        setMaritalStatus((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((id) => id !== value)
        );
    };

    const handleCheckboxChange = (day_id) => {
        setSelectedDays((prevSelectedDays) =>
          prevSelectedDays.includes(day_id)
            ? prevSelectedDays.filter((id) => id !== day_id)
            : [...prevSelectedDays, day_id]
        );
    };

    const openEditModal = async (id) => {
        setItemId(id);
        setIsEditModal(true);
    }

    const handleEditModal = (e) => {
        setIsEditModal(false);
    }

    const handleAddData = async () => {

        if (!image) {
            toast.error('Image cannot be blank');
            return;
        }

        if (!isImageValid) {
            toast.error('Image dimensions should be 800px x 320px.');
            return;
        }

        if (!totalAudienceNo) {
            toast.error('Please calculate the reach before proceed.');
            return;
        }

        const time_From = timeFrom ? timeFrom.format('HH:mm') : '';
        const time_To = timeTo ? timeTo.format('HH:mm') : '';

        const workplaces = transformSelectedExtraData(selectedExtraData);
        const colleges = transformSelectedExtraData(selectedCollegeData);
        const schools = transformSelectedExtraData(selectedSchoolData);
        const hometown = transformSelectedExtraData(selectedHometownData);
        const currentCity = transformSelectedExtraData(selectedCurrentCity);

        let formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('gender', gender);
        formData.append('min_age', minAge);
        formData.append('max_age', maxAge);
        formData.append('location_type', locationType);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('city', city);
        formData.append('hometowns', hometown);
        formData.append('currentcities', currentCity);
        formData.append('workplaces', workplaces);
        formData.append('colleges', colleges);
        formData.append('schools', schools);
        formData.append('maritalstatus', maritalStatus);
        formData.append('days', selectedDays);
        formData.append('from_time', time_From);
        formData.append('to_time', time_To);
        formData.append('entry_pic', image);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('date', scheduledDate);
        formData.append('time', scheduledTime);
        formData.append('offer', offerId);
        formData.append('status', status);

        setLoader(true);
        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.pushEntrySaveEntry}`,
                data: formData
            });

            if (response.data.status === 'completed') {
                Swal.fire({
                    icon: 'success',
                    text: 'Push Notification added successfully!',
                    confirmButtonText: 'OK'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/listing/push-notifications';
                    }
                });
            } else if (response.data.status === 'error') {
                response.data.list.map((error) => (
                    toast.error(error)
                ));
            } else {
                setError('Error in saving data.');
            }
        } catch (err) {
            setError('An error occurred while saving data.');
        } finally {
            setLoader(false);
        }
    }

    const handleCalculation = async () => {

        const time_From = timeFrom ? timeFrom.format('HH:mm') : '';
        const time_To = timeTo ? timeTo.format('HH:mm') : '';
        const min_age = minAge ? minAge : '--Minimum--';
        const max_age = maxAge ? maxAge : '--Maximum--';

        const workplaces = transformSelectedExtraData(selectedExtraData);
        const colleges = transformSelectedExtraData(selectedCollegeData);
        const schools = transformSelectedExtraData(selectedSchoolData);
        const hometown = transformSelectedExtraData(selectedHometownData);
        const currentCity = transformSelectedExtraData(selectedCurrentCity);

        let formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('gender', gender);
        formData.append('min_age', min_age);
        formData.append('max_age', max_age);
        formData.append('location_type', locationType);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('city', city);
        formData.append('hometowns', hometown);
        formData.append('currentcities', currentCity);
        formData.append('workplaces', workplaces);
        formData.append('colleges', colleges);
        formData.append('schools', schools);
        formData.append('maritalstatus', maritalStatus);
        formData.append('days', selectedDays);
        formData.append('from_time', time_From);
        formData.append('to_time', time_To);

        setLoader(true);
        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.calculateTargetAudience}`,
                data: formData
            });

            if (response.data.status === 'ok') {
                setTotalAudience(response.data.total_audience_count_display);
                setTotalAudienceNo(response.data.total_audience_count);
            } else if (response.data.status === 'error') {
                response.data.list.map((error) => (
                    toast.error(error)
                ));
            } else {
                setError('Error in saving data.');
            }
        } catch (err) {
            setError('An error occurred while saving data.');
        } finally {
            setLoader(false);
        }
    }

    const openDeleteModal = async (id, name) => {
        setItemId(id);
        setItemName(name);
        setIsDeleteModal(true);
    };

    const handleItemDelete = async () => {
        if (businessListingId && token) {
          const cData = new FormData();
          cData.append('token', token);
          cData.append('listing_id', businessListingId);
          cData.append('pn_id', itemId);
    
          try {
            const response = await axios({
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              url: `${params.baseURL}${params.endpoints.pushEntryUpdateEntry}`,
              data: cData
            });
            if (response.data.status === 'completed') {
                setIsDeleteModal(false);
                toast.success('Push notification deleted successfully.');
                fetchMenuList();
            }
            else if(response.data.status === 'error') {
              setError(response.data.list);
            }
            else {
              setError('Error Deleting Data.');
            }
          } catch (error) {
            setError(error.message);
          }
        }
    };

    const changeStatus = async (menuItemId, newStatus) => {
        if (businessListingId && token) {
            const cData = new FormData();
            cData.append('token', token);
            cData.append('listing_id', businessListingId);
            cData.append('pn_id', menuItemId);
            cData.append('onoff', newStatus);
      
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.pushEntryStatusUpdate}`,
                data: cData
              });
              if (response.data.status === 'completed') {
                //toast.success('Status changed successfully.');
                setMenuList(prevList =>
                    prevList.map(item =>
                        item.pn_id === menuItemId ? { ...item, pn_status: newStatus } : item
                    )
                );
              }
              else if(response.data.status === 'error') {
                setError(response.data.list);
              }
              else {
                setError('Error changing status.');
              }
            } catch (error) {
              setError(error.message);
            }
        }
    }

    const handleAttachOffer = (id, name, code) => {
        setTitle(name);
        setOfferId(id);
        setSelectedOffer({ id, name, code });
        setShowOfferPopup(false);
    };
    
    const handleRemoveOffer = () => {
        setTitle('');
        setSelectedOffer(null);
    };

    return (
        <div className="manage-section">
            {loader && <Loader />}
            {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 

            <div className='lsertbox'>
                <h4>Push Notifications</h4>
            </div>
            <div className='search-box'>
                <input type="text" className="form-control" placeholder="Search" value={search} onChange={handleSearchChange} />
            </div>

            {loading ? (
                <Loading />
            ) : (
                <div className="menu-item-list">
                    <div>
                        {menuList.length > 0 ? (<>
                            {menuList.map((item) => (
                                <div className='ml-box lser-box' key={item.pn_id}>
                                    <div className='ml-header lser-header'>
                                        <div className='lser-pn'>
                                            <div>
                                                <h4 className='lser-name mb-4'>{item.pn_name}</h4>
                                            </div>
                                            <div className='shsnbox'>
                                                <div>
                                                    <span>Scheduled on</span>
                                                    <p>{item.pn_datetime}</p>
                                                </div>
                                                <div>
                                                    <span>Sent on</span>
                                                    <p>{item.pn_sent_on ? item.pn_sent_on : '---'}</p>
                                                </div>
                                            </div>
                                            <div className='rsfbox'>
                                                <div>
                                                    <span>Receipients</span>
                                                    <p>{item.pn_total_receipient}</p>
                                                </div>
                                                <div>
                                                    <span style={{color:'green'}}>Sent</span>
                                                    <p>{item.pn_total_sent ? item.pn_total_sent : '---'}</p>
                                                </div>
                                                <div>
                                                    <span style={{color:'red'}}>Failed</span>
                                                    <p>{item.pn_total_failed ? item.pn_total_failed : '---'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ml-body lser-body'>
                                        <div className='lserv-btn-box'>
                                            <button className="ml-btn fs-success" onClick={() => openEditModal(item.pn_id)} >
                                                Edit
                                            </button>
                                            <button className="ml-btn fs-danger" onClick={() => openDeleteModal(item.pn_id, item.pn_name)}  >
                                                Delete
                                            </button>
                                        </div>
                                        <div class="toggle">                                                                                   
                                            <input 
                                                type="checkbox" 
                                                name="status" 
                                                id={`ostatus_${item.pn_id}`}
                                                checked={item.pn_status === 1}
                                                onChange={() => changeStatus(item.pn_id, item.pn_status === 1 ? 2 : 1)}
                                            /> 
                                            <label htmlFor={`ostatus_${item.pn_id}`} style={{cursor:'pointer'}}></label>  
                                        </div>
                                    </div>
                                    <div className='ml-footer'>
                                        <p>Created : {item.pn_created_time}</p>
                                        <p>Modified : {item.pn_last_modified_time ? item.pn_last_modified_time : '---'}</p>
                                    </div>
                                </div>
                            ))}
                        </>) : (<>
                            <div className='empty-section'>
                                <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                                <p>No Push Notification Found</p>
                            </div>
                        </>)}
                    </div>
                </div>
            )}

            {isAddPopup && (
                <div className='popup'>
                    <div className='popup-content mb-5'>
                    <SubscriptionCheck>
                    {loading && <p>Loading...</p>}
                    {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 
                        <div className='cpo-header' style={{ margin: '0' }}>
                            <div>
                                <h4>Create Push Notification</h4>
                            </div>
                            <span className='popup-close' onClick={() => setIsAddPopup(false)}>&times;</span>
                        </div>
                        <div className='cposb'>
                            <form className="form-section needs-validation" encType="multipart/form-data">
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                    <label htmlFor="push_notification_img" className="logo-title" style={{ cursor: 'pointer' }}>
                                        {image ? (
                                            <img src={URL.createObjectURL(image)} alt="Profile" className="upload_img" />
                                        ) : (
                                            <img src={'/assets/img/background/menu_banner.png'} alt="Profile" className="upload_img" />
                                        )}
                                        <input 
                                            type="file" 
                                            name="push_notification_img" 
                                            id="push_notification_img" 
                                            className="upload d-none" 
                                            onChange={handleImageUpload} 
                                        />
                                        <span className='image__upload_btn'>select image</span>
                                    </label>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <input type="text" id="title" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                            <label htmlFor="title">Title (Required)</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <textarea type="text" rows={4} id="description" className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                                            <label htmlFor="description">Message (Optional)</label>
                                        </div>
                                    </div>
                                    {selectedOffer ? (
                                        <div className="col-md-12 mb-3 text-center">
                                            <p className='coupon_code' style={{margin:'auto', marginBottom:'20px'}}>{selectedOffer.code}</p>
                                            <span className='remove-offer-txt' onClick={handleRemoveOffer}>Remove Attached Offer</span>
                                        </div>
                                    ) : (
                                        <div className='col-md-12 mb-3 text-center'>
                                            <span className='attach-txt' onClick={() => setShowOfferPopup(true)}>Attach Offer</span>
                                        </div>
                                    )}
                                    <div class="col-md-12 mb-3">
                                        <div className='pntbox'>
                                            <p>Scheduled date/time</p>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                            <select id="scheduledDate" className='form-control' value={scheduledDate} onChange={handleScheduledDate}>
                                                {scheduledDateList.map((date, index) => (
                                                <option key={index} value={date}>
                                                    {date}
                                                </option>
                                                ))}
                                            </select>
                                            </div>
                                            <div className='col-md-6'>
                                            <select id="scheduledTime" className='form-control' value={scheduledTime} onChange={handleScheduledTime}>
                                                {scheduledTimeList.map((time, index) => (
                                                <option key={index} value={time}>
                                                    {time}
                                                </option>
                                                ))}
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mb-3">
                                        <div className='pntbox'>
                                            <p>target Audience</p>
                                        </div>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <legend class="col-form-label">Gender</legend>
                                        <div className='radio-box'>
                                            {genderList.map((type) => (
                                                <div className="form-check lserv-form-check" key={type.id}>
                                                    <label className="lserv-radio-label" htmlFor={type.title.toLowerCase().replace(' ', '')}>
                                                        <input 
                                                            name='category'
                                                            className="lserv-radio-input" 
                                                            type="checkbox" 
                                                            id={type.title.toLowerCase().replace(' ', '')} 
                                                            value={type.id}
                                                            onChange={handleGenderChange}
                                                        />
                                                        <span className='lserv-radio-span'><em><i class="fas fa-check"></i></em>{type.title}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <legend class="col-form-label">Age</legend>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                            <select id="ageSelect" className='form-control' value={minAge} onChange={handleMinAgeChange}>
                                                {minAgeList.map((age, index) => (
                                                <option key={index} value={age}>
                                                    {age}
                                                </option>
                                                ))}
                                            </select>
                                            </div>
                                            <div className='col-md-6'>
                                            <select id="ageSelect" className='form-control' value={maxAge} onChange={handleMaxAgeChange}>
                                                {maxAgeList.map((age, index) => (
                                                <option key={index} value={age}>
                                                    {age}
                                                </option>
                                                ))}
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3 char-lists">
                                        <legend className="col-form-label">Location</legend>
                                        {locationTypeList.map((type) => (
                                            <div className="form-check" key={type.id}>
                                                <input 
                                                    name='locationType'
                                                    className="form-check-input" 
                                                    type="radio"
                                                    id={type.title.toLowerCase().replace(' ', '')} 
                                                    value={type.id}
                                                    onChange={handleLocationTypeChange} 
                                                />
                                                <label className="form-check-label" htmlFor={type.title.toLowerCase().replace(' ', '')}>
                                                    {type.title}
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                    { locationType === '1' && (
                                    <>
                                    <div className='col-md-12 mb-3'>
                                        <select id="country" className='form-control' value={country} onChange={handleCountryChange}>
                                            {countryList.map((list, index) => (
                                            <option key={index} value={list.country_id}>
                                                {list.country_name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <select id="state" className='form-control' value={state} onChange={handleStateChange}>
                                            {StateList.map((list, index) => (
                                            <option key={index} value={list.state_id}>
                                                {list.state_name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <select id="city" className='form-control' value={city} onChange={handleCityChange}>
                                            {cityList.map((list, index) => (
                                            <option key={index} value={list.city_id}>
                                                {list.city_name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    </>
                                    )}

                                    <div className='col-md-12 mb-3'>
                                        <div className='checkPop'>
                                            <button type='button' className='select-btn' onClick={() => openHometownDataModel()}>Select Hometown</button>
                                            <ExtraNotificationPopup
                                                isOpen={hometownDataModel}
                                                onClose={closeHometownDataModel}
                                                onSelect={handleHometownDataSelectedItems}
                                                onDone={closeHometownDataModel}
                                                selectedItems={selectedHometownData}
                                            />
                                        </div>
                                        <div className='sdcb'>
                                            {selectedHometownData.map(data => (
                                            <div className='sdlb' key={data.data_id}>
                                                {data.data_name}
                                                <span className='rdflb' onClick={() => handleHometownDataRemoveItem(data.data_id)}>
                                                X
                                                </span>
                                            </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <div className='checkPop'>
                                            <button type='button' className='select-btn' onClick={() => openCurrentCityDataModel()}>Select Currrent City</button>
                                            <ExtraNotificationPopup
                                                isOpen={currentcityDataModel}
                                                onClose={closeCurrentCityDataModel}
                                                onSelect={handleCurrentCitySelectedItems}
                                                onDone={closeCurrentCityDataModel}
                                                selectedItems={selectedCurrentCity}
                                            />
                                        </div>
                                        <div className='sdcb'>
                                            {selectedCurrentCity.map(data => (
                                            <div className='sdlb' key={data.data_id}>
                                                {data.data_name}
                                                <span className='rdflb' onClick={() => handleCurrentCityDataRemoveItem(data.data_id)}>
                                                X
                                                </span>
                                            </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <div className='checkPop'>
                                            <button type='button' className='select-btn' onClick={() => openExtraDataPopup()}>Select Workplace</button>
                                            <ExtraDataPopup
                                                isOpen={extraDataModel}
                                                onClose={closeExtraDataModal}
                                                onSelect={handleExtraDataSelectedItems}
                                                onDone={closeExtraDataModal}
                                                selectedItems={selectedExtraData}
                                                type={1}
                                            />
                                        </div>
                                        <div className='sdcb'>
                                            {selectedExtraData.map(data => (
                                            <div className='sdlb' key={data.data_id}>
                                                {data.data_name}
                                                <span className='rdflb' onClick={() => handleExtraDataRemoveItem(data.data_id)}>
                                                X
                                                </span>
                                            </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <div className='checkPop'>
                                            <button type='button' className='select-btn' onClick={() => openCollegeDataPopup()}>Select Institute/College</button>
                                            <ExtraDataPopup
                                                isOpen={collegeDataModel}
                                                onClose={closeCollegeDataModal}
                                                onSelect={handleCollegeDataSelectedItems}
                                                onDone={closeCollegeDataModal}
                                                selectedItems={selectedCollegeData}
                                                type={3}
                                            />
                                        </div>
                                        <div className='sdcb'>
                                            {selectedCollegeData.map(data => (
                                            <div className='sdlb' key={data.data_id}>
                                                {data.data_name}
                                                <span className='rdflb' onClick={() => handleCollegeDataRemoveItem(data.data_id)}>
                                                X
                                                </span>
                                            </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <div className='checkPop'>
                                            <button type='button' className='select-btn' onClick={() => openSchoolDataPopup()}>Select School/Highschool</button>
                                            <ExtraDataPopup
                                                isOpen={schoolDataModel}
                                                onClose={closeSchoolDataModal}
                                                onSelect={handleSchoolDataSelectedItems}
                                                onDone={closeSchoolDataModal}
                                                selectedItems={selectedSchoolData}
                                                type={2}
                                            />
                                        </div>
                                        <div className='sdcb'>
                                            {selectedSchoolData.map(data => (
                                            <div className='sdlb' key={data.data_id}>
                                                {data.data_name}
                                                <span className='rdflb' onClick={() => handleSchoolDataRemoveItem(data.data_id)}>
                                                X
                                                </span>
                                            </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <legend class="col-form-label">Marital Status</legend>
                                        <div className='radio-box'>
                                            {maritalList.map((type) => (
                                                <div className="form-check lserv-form-check" key={type.id}>
                                                    <label className="lserv-radio-label" htmlFor={type.title.toLowerCase().replace(' ', '')}>
                                                        <input 
                                                            name='category'
                                                            className="lserv-radio-input" 
                                                            type="checkbox" 
                                                            id={type.title.toLowerCase().replace(' ', '')} 
                                                            value={type.id}
                                                            onChange={handleMaritalChange}
                                                        />
                                                        <span className='lserv-radio-span'><em><i class="fas fa-check"></i></em>{type.title}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='col-md-12 mb-3 char-lists'>
                                        <legend class="col-form-label">Specific Check-in at day/time</legend>
                                        {daysOfWeek.map((day) => (
                                            <div key={day.day_id} className="form-check">
                                                <input
                                                    className="form-check-input" 
                                                    type="checkbox"
                                                    id={day.short_name.toLowerCase().replace(' ', '')} 
                                                    value={day.day_id}
                                                    checked={selectedDays.includes(day.day_id)}
                                                    onChange={() => handleCheckboxChange(day.day_id)}
                                                />
                                                <label className="form-check-label" htmlFor={day.short_name.toLowerCase().replace(' ', '')}>  
                                                    {day.short_name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileTimePicker
                                                value={timeFrom}
                                                onChange={handleTimeFromChange}
                                                slotProps={{ textField: { fullWidth: true, inputProps: { placeholder: 'Time From' } } }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            value={timeTo}
                                            onChange={handleTimeToChange}
                                            slotProps={{ textField: { fullWidth: true, inputProps: { placeholder: 'Time To' } } }}
                                        />
                                        </LocalizationProvider>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <span className="cp-btn text-uppercase" 
                                            style={{backgroundColor:'#006400', cursor:'pointer'}} 
                                            onClick={handleCalculation}>
                                            Calculate Reach
                                        </span>
                                    </div>

                                    { totalAudience && (
                                    <div className='col-md-12 mb-3'>
                                        <div className='tadb'>
                                            <span>Total Audience</span>
                                            <span style={{color:'#83ff88'}}>{totalAudience}</span>
                                        </div>
                                    </div>
                                    )}
                                    
                                    <div className="col-md-12">
                                        <div className='status__box'>
                                            <div>
                                                <label>Status</label>
                                            </div>
                                            <div class="toggle">                                                                                   
                                                <input 
                                                    type="checkbox" 
                                                    name="addStatus" 
                                                    id="addStatus" 
                                                    checked={status === 1}
                                                    onChange={handleStatusChange}
                                                /> 
                                                <label for="addStatus" style={{cursor:'pointer'}}></label>  
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-12 text-end'>
                                        <button type="button" className="cp-btn" onClick={handleAddData}>SAVE</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </SubscriptionCheck>
                    </div>
                </div>
            )}

            {isDeleteModal && itemId &&(
                <div className='modal-overlay'>
                <div className='modal-content'>
                    <div className='modal-body'>
                    <h6>Delete</h6>
                    <p>Would you like to delete push notification with title {itemName} ?</p>
                    </div>
                    <div className='modal-footer'>
                        <button className="close-button" onClick={() => setIsDeleteModal(false)} style={{marginRight:'4rem'}}>No</button>
                        <button className="close-button" onClick={handleItemDelete}>Yes</button>
                    </div>
                </div>
                </div>
            )}

            {isEditModal && (
                <EditPushNotification
                    itemId={itemId}
                    onClose={handleEditModal}
                />
            )}

            {showOfferPopup && (
            <AttachOffer
                show={showOfferPopup}
                onHide={() => setShowOfferPopup(false)}
                onSelectOffer={handleAttachOffer}
            />
            )}

            <button className='add-data' onClick={() => setIsAddPopup(true)}><i className="fas fa-plus"></i></button>
        </div>
    );
}

export default PushNotifications;
