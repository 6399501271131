import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from './Loading';
import StackGrid from "react-stack-grid";
import PhotoItemsPopup from '../components/PhotoItemsPopup';
import VideoItemsPopup from '../components/VideoItemsPopup';
import LiveCrowdUserRating from './LiveCrowdUserRating';

const VideoWithCustomPlayButton = ({
    videoSrc,
    onClick,
    onLoad,
    handleVideoClick,
    photoItems,
    name,
    profileImage,
    sharingText,
    sharingUrl
  }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
  
    const handlePlayPause = () => {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
        handleVideoClick(photoItems, name, profileImage, sharingText, sharingUrl);
      }
      setIsPlaying(!isPlaying);
    };
  
    return (
      <>
        <video
          className='lp-img'
          ref={videoRef}
          src={videoSrc}
          style={{ width: '100%', height: 'auto' }}
          onClick={handlePlayPause}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onLoadedData={onLoad}
          controls={false}
        />
        {!isPlaying && (
          <button
            onClick={handlePlayPause}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'rgba(0, 0, 0, 1)',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              fontSize: '20px',
              cursor: 'pointer'
            }}
          >
            <img src={'/assets/img/icons/play_btn.svg'} alt='Play' className='play-btn w-100' />
          </button>
        )}
      </>
    );
};

const LiveCrowdUserDetails = ({ itemId, onClose }) => {

    const [userDetails, setUserDetails] = useState('');
    const [ratingDetails, setRatingDetails] = useState('');
    const [userRatingModal, setUserRatingModal] = useState(false);
    const [userId, setUserId] = useState(itemId);
    const [taggedPhotos, setTaggedPhotos] = useState([]);
    const [isPhotoItemModal, setIsPhotoItemModal] = useState(false);
    const [isVideoItemModal, setIsVideoItemModal] = useState(false);
    const [popupImages, setPopupImages] = useState([]);
    const [photoOwnerImage, setPhotoOwnerImage] = useState('');
    const [selectedListingName, setSelectedListingName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalTaggedItems, setTotalTaggedItems] = useState('');
    const [sharingText, setSharingText] = useState('');
    const [sharingUrl, setSharingUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    const gridRef = useRef(null);
    const [imagesLoaded, setImagesLoaded] = useState(0);

    useEffect(() => {
        if (imagesLoaded === taggedPhotos.length && gridRef.current) {
        gridRef.current.updateLayout();
        }
    }, [imagesLoaded, taggedPhotos]);

    const handleImageLoad = () => {
        setImagesLoaded(prevCount => prevCount + 1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleImageClick = (photoItems, name, profileImage, sharingText, sharingUrl) => {
        setPopupImages(photoItems);
        setSelectedListingName(name);
        setPhotoOwnerImage(profileImage);
        setSharingText(sharingText);
        setSharingUrl(sharingUrl);
        setIsPhotoItemModal(true);
    };
      
    const closePhotoItemModal = () => {
        setIsPhotoItemModal(false);
        setPopupImages([]);
    };
    
    const handleVideoClick = (photoItems, name, profileImage, sharingText, sharingUrl) => {
        setPopupImages(photoItems);
        setSelectedListingName(name);
        setPhotoOwnerImage(profileImage);
        setSharingText(sharingText);
        setSharingUrl(sharingUrl);
        setIsVideoItemModal(true);
    };
    
    const closeVideoItemModal = () => {
        setIsVideoItemModal(false);
        setPopupImages([]);
    };

    const fetchUserTaggedPhotos = useCallback(async () => {
        if (businessListingId && token) {
          const formData = new FormData();   
          formData.append('listing_id', businessListingId);
          formData.append('profile_id', itemId);
          formData.append('token', token);
          formData.append('utype', 'captain');
          setLoading(true);
          const url = `${params.baseURL}${params.endpoints.userTaggedPhotos}?page=${currentPage}`;
          try {
            const response = await axios({
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              url: url,
              data: formData
            });
            if (response.data.items && Array.isArray(response.data.items)) {
              setTaggedPhotos(response.data.items);
              setTotalPages(response.data._meta.pageCount);
              setTotalTaggedItems(response.data._meta.totalCount);
            }
            else {
              setError('Listing not found');
            }
          } catch (error) {
            setError(error.message);
          } finally {
            setLoading(false);
          }
        }
    }, [businessListingId, token, itemId, currentPage]);

    useEffect(() => {
        
        const fetchUserProfile = async () => {

            if (businessListingId && token) {
                setLoading(true);
                const formData = new FormData();
                formData.append('listing_id', businessListingId);
                formData.append('token', token);
                formData.append('id', itemId);
                formData.append('utype', 'captain');
                formData.append('listing_type', 'listing');

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.getUserProfile}`,
                        data: formData
                    });
                    if (response.data.status === 'found') {
                        setUserDetails(response.data.customer_profile_data);
                        setRatingDetails(response.data.customer_rating_info);
                        fetchUserTaggedPhotos();
                    }
                    else {
                        console.log('Profile not found.');
                    }
                } catch (error) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchUserProfile();

    }, [businessListingId, token, itemId, fetchUserTaggedPhotos]);

    const handleUserRating = (id) => {
        setUserRatingModal(true);
        setUserId(id);
    };
    
    const closeUserRating = () => {
        setUserRatingModal(false);
    }

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
          stars.push(
            <span key={i} className={`star ${i <= rating ? 'filled' : 'empty'}`}>
              &#9733;
            </span>
          );
        }
        return stars;
    };

    return (
        <>
            <div className='popup'>
                <div className='popup-content mb-5'>
                {loading && <Loading />}
                {error && <div className="alert alert-danger">{error}</div>} 
                    <div className='cpo-header' style={{ margin: '0' }}>
                        <div>
                            <h4>Profile</h4>
                        </div>
                        <span className='popup-close' onClick={onClose}>&times;</span>
                    </div>
                    <div className='cposb' style={{marginTop: '20px'}}>
                        <div className='lcudpds'>
                            <div className='lcudirb'>
                                <img src={userDetails.customer_pic_image_url} alt='' />
                                <div className='lcudrb'>
                                    <p>{renderStars(userDetails.customer_rating)}</p>
                                    <button type='button' 
                                        className='rate-btn' 
                                        onClick={() => handleUserRating(userDetails.customer_id)}
                                    >
                                        {ratingDetails ? ('Edit Rating') : ('Rate customer')}
                                    </button>
                                </div>
                            </div>
                            <div className='lcudun'>
                                <p className='lcud_name'>{userDetails.customer_name}</p>
                                <p>@{userDetails.customer_username}</p>
                            </div>
                        </div>
                        <div>
                        {loading ? (
                            <Loading />
                            ) : taggedPhotos.length > 0 ? (
                            <>
                                <StackGrid
                                className={'photo_grid_view mt-4'}
                                columnWidth={200}
                                gutterWidth={16}
                                gutterHeight={16}
                                monitorImagesLoaded={true}
                                ref={gridRef}
                                >
                                {taggedPhotos.map(item => (
                                    <div className='lpib' key={item.photo_id}>
                                    {item.media_type === "P" ? (
                                        <img
                                        className='lp-img'
                                        src={item.photo_image_url}
                                        alt={item.photo_caption}
                                        onClick={() => handleImageClick(
                                            item.photo_items, 
                                            item.owner_info.title, 
                                            item.owner_info.image,
                                            item.sharing_text,
                                            item.sharing_url
                                        )} 
                                        onLoad={handleImageLoad}
                                        />
                                    ) : item.media_type === "V" ? (
                                        <VideoWithCustomPlayButton
                                        videoSrc={item.photo_image_url}
                                        onLoad={handleImageLoad}
                                        handleVideoClick={handleVideoClick}
                                        photoItems={item.photo_items}
                                        name={item.owner_info.title}
                                        profileImage={item.owner_info.image}
                                        sharingText={item.sharing_text}
                                        sharingUrl={item.sharing_url}
                                        />
                                    ) : null}
                                    <div className='lpib-overlay'>
                                        <div className='lpib-overlay-img'>
                                        <img src={item.owner_info.image} alt={item.owner_info.title} />
                                        </div>
                                        <div className='lpib-overlay-text'>
                                        <p>{item.created_time_as_text}</p>
                                        <p>{item.owner_info.title}</p>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                </StackGrid>
                                {totalPages > 1 && (
                                <div className="pagination">
                                    <button 
                                        onClick={() => handlePageChange(1)}
                                        className={currentPage === 1 ? 'page-btn disabled' : 'page-btn'}
                                        disabled={currentPage === 1}
                                    >
                                        First
                                    </button>
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <button 
                                            key={index + 1}
                                            onClick={() => handlePageChange(index + 1)}
                                            className={currentPage === index + 1 ? 'page-btn active' : 'page-btn'}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                    <button 
                                        onClick={() => handlePageChange(totalPages)}
                                        className={currentPage === totalPages ? 'page-btn disabled' : 'page-btn'}
                                        disabled={currentPage === totalPages}
                                    >
                                        Last
                                    </button>
                                </div>
                            )}
                            </>
                            ) : (
                            <div>
                                <div className='empty-section'>
                                <img src={'assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                                <p>No Tagged Photos</p>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>

                {userRatingModal &&
                    <LiveCrowdUserRating
                        itemId={userId}
                        onClose={closeUserRating}
                    />
                }

                {isPhotoItemModal && 
                    <PhotoItemsPopup 
                    images={popupImages} 
                    businessName={selectedListingName} 
                    profileImage={photoOwnerImage}
                    sharingText={sharingText}
                    sharingUrl={sharingUrl}
                    onClose={closePhotoItemModal}
                    />
                }

                {isVideoItemModal && 
                    <VideoItemsPopup 
                    images={popupImages} 
                    businessName={selectedListingName} 
                    profileImage={photoOwnerImage}
                    sharingText={sharingText}
                    sharingUrl={sharingUrl}
                    onClose={closeVideoItemModal}
                    />
                }

            </div>
        </>
    )
}

export default LiveCrowdUserDetails