import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';

function ManageListing() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchListing = async () => {

      const captainData = getItemFromLocalStorage('captainProfile');
      let formData = new FormData();
      formData.append('token', captainData.captain_access_token);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.getListing}`,
          data: formData
        });

        if (response.data.items && Array.isArray(response.data.items)) {
          setData(response.data.items);
        } else {
          setError(response.message || 'Error in fetching data.');
        }
      } catch (error) {
        setError('Error fetching My Subscriptions.');
      } finally {
        setLoading(false);
      }
    };

    fetchListing();
  }, []);

  if (loading) {
    return <div><Loading /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div>
      {data.length > 0 ? (
            data.map(item => (
                <div className='ml-box' key={item.id}>
                    <div className='ml-header'>
                        <div>
                            <img src={'assets/img/fs-verified-shield.svg'} alt={item.business_name} className='ml-image' />
                        </div>
                        <div>
                            <h4>{item.business_name}</h4>
                            <p className='ml-city'>{item.business_city}</p>
                            {item.is_profile_verified === 0 ? (
                              <p className='ml-pvo' style={{color:'red'}}>Verification is pending.</p>
                            ) : item.is_profile_verified === 1 ? (
                              <p className='ml-pvo'>Verified on : {item.profile_verified_on}</p>
                            ) : item.is_profile_verified === 2 ? (
                              <p className='ml-pvo' style={{color:'red'}}>{item.verification_comments}</p>
                            ) : <p className='ml-pvo' style={{color:'red'}}>Verification is pending.</p>}
                        </div>
                    </div>
                    <div className='ml-body'>
                        <Link className="ml-edit-btn" to={`/edit-listing/${item.listing_id}`}>
                            <span>Edit</span>
                        </Link>
                    </div>
                    <div className='ml-footer'>
                        <p>Created 0n : {item.business_created_time}</p>
                        <p>Modified 0n : {item.business_last_modified_time ? item.business_last_modified_time : '---'}</p>
                    </div>
                </div>
            ))
        ) : (
          <div className='empty-section'>
              <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
              <p>Business Not Listing Found</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ManageListing;