import React from 'react';
import { Navigate } from 'react-router-dom';
import { getWithExpiry } from './utils/storageUtils';

const ProtectedRoute = ({ children }) => {
  const captainId = getWithExpiry('captain_id');

  return captainId ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
