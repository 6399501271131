function Loading() {
    return (
        <div className="loading-div">
            {/*<box class="shine"></box>
            <div className="loading-div">
            <lines class="shine"></lines>
            <lines class="shine"></lines>
            <lines class="shine"></lines>
            </div>*/}
            <div class="spinner center">
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
                <div class="spinner-blade"></div>
            </div>
        </div>
    )
}

export default Loading;