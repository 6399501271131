import React, { useEffect, useState } from "react";
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from "../components/Loading";

function Ratings() {

    const [loading, setLoading] = useState(false);
    const [ratingList, setRatingList] = useState([]);
    const [expandedItems, setExpandedItems] = useState({});
    const [filteredRating, setFilteredRating] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState(0);

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    useEffect(() => {

        const fetchRatingList = async () => {
          if (businessListingId && token) {
            setLoading(true);
            const formData = new FormData();
            formData.append('token', token);
            formData.append('listing_id', businessListingId);
            formData.append('listing_type', 'listing');
            formData.append('rating', filteredRating);
    
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.ratingList}`,
                data: formData
              });
              if (response.data.items && Array.isArray(response.data.items)) {
                setRatingList(response.data.items);
              }
              else {
                console.log('Rating List Not Found');
              }
            } catch (error) {
              console.log(error.message);
            } finally {
              setLoading(false);
            }
          }
        };
    
        fetchRatingList();
    }, [businessListingId, token, filteredRating]);

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= Math.floor(rating)) {
                stars.push(
                    <span key={i} className="star filled">
                        <i className="fas fa-star"></i>
                    </span>
                );
            } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
                stars.push(
                    <span key={i} className="star filled">
                        <i class="fas fa-star-half"></i>
                    </span>
                );
            } else {
                stars.push(
                    <span key={i} className="star empty">
                        <i class="far fa-star"></i>
                    </span>
                );
            }
        }
        return stars;
    };

    const toggleExpand = (index) => {
        setExpandedItems(prevState => ({
          ...prevState,
          [index]: !prevState[index]
        }));
    };  

    const filterByRating = (rating) => {
        setFilteredRating(rating);
        setSelectedFilter(rating);
    };

    return (
        <div className="ratings">
            <div className="ratings__header">
                <span onClick={() => filterByRating(0)}>
                    All {selectedFilter === 0 && <em className="selected_rating"><i className="fas fa-check"></i></em>}
                </span>
                {[1, 2, 3, 4, 5].map(rating => (
                    <span key={rating} onClick={() => filterByRating(rating)}>
                        {rating} <i className="fas fa-star fr__star"></i> 
                        {selectedFilter === rating && <em className="selected_rating"><i className="fas fa-check"></i></em>}
                    </span>
                ))}
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    {ratingList.length > 0 ? (
                    <>
                        {ratingList.map((list, index) => (
                            <div className="rlsection">
                                <div className="rupb">
                                    <img className="user-icon" src={list.owner_info.image} alt="User Profile" />
                                    <div>
                                        <p>{list.owner_info.title}</p>
                                        <p style={{fontSize:'11px', fontWeight:'400'}}>{list.rating_date}</p>
                                    </div>
                                </div>
                                <div className="rsbox" onClick={() => toggleExpand(index)}>
                                    <p className="rstars">{renderStars(list.overall_rating)} </p>
                                    <p style={{ cursor: 'pointer', color:'#fff' }}>
                                        <i className={`fas fa-chevron-${expandedItems[index] ? 'up' : 'down'}`}></i>
                                    </p>
                                </div>
                                {expandedItems[index] && (
                                    <div className="rl-details">
                                        <p>
                                            <img src={'/assets/img/icons/fs_food_rating.svg'} alt="" className="rating-icon" />
                                            <span>Food</span> 
                                            <div className="rating-stars">{renderStars(list.food_rating)}</div>
                                        </p>
                                        <p>
                                            <img src={'/assets/img/icons/fs_food_presentation_rating.svg'} alt="" className="rating-icon" />
                                            <span>Food Presentation</span> 
                                            <div className="rating-stars">{renderStars(list.food_presentation)}</div>
                                        </p>
                                        <p>
                                            <img src={'/assets/img/icons/fs_ambience_rating.svg'} alt="" className="rating-icon" />
                                            <span>Ambience</span> 
                                            <div className="rating-stars">{renderStars(list.ambience_rating)}</div>
                                        </p>
                                        <p>
                                            <img src={'/assets/img/icons/fs_crowd_rating.svg'} alt="" className="rating-icon" />
                                            <span>Crowd</span> 
                                            <div className="rating-stars">{renderStars(list.crowd_rating)}</div>
                                        </p>
                                        <p>
                                            <img src={'/assets/img/icons/fs_pricing_rating.svg'} alt="" className="rating-icon" />
                                            <span>Pricing</span> 
                                            <div className="rating-stars">{renderStars(list.pricing_rating)}</div>
                                        </p>
                                        <p>
                                            <img src={'/assets/img/icons/fs_food_presentation_rating.svg'} alt="" className="rating-icon" />
                                            <span>Service</span> 
                                            <div className="rating-stars">{renderStars(list.service_rating)}</div>
                                        </p>
                                        <p>
                                            <img src={'/assets/img/icons/fs_food_presentation_rating.svg'} alt="" className="rating-icon" />
                                            <span>Staff Behaviour</span> 
                                            <div className="rating-stars">{renderStars(list.staff_behaviour_rating)}</div>
                                        </p>
                                        
                                        <div className="rcbox">
                                        {list.comment ? (
                                            <p>{list.comment}</p>
                                        ) : ('')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </>
                    ) : (
                        <div>
                            <div className='empty-section'>
                                <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                                <p>No Rating Available</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Ratings;