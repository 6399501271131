import React, { useEffect, useState, useCallback , useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import StackGrid, { transitions } from "react-stack-grid";
import FollowersListPopup from '../components/FollowersListPopup';
import PhotoItemsPopup from '../components/PhotoItemsPopup';
import VideoItemsPopup from '../components/VideoItemsPopup';
import Loading from '../components/Loading';
import { Helmet } from 'react-helmet';
import LiveCrowdUserDetails from '../components/LiveCrowdUserDetails';

const { scaleDown } = transitions;

const VideoWithCustomPlayButton = ({
  videoSrc,
  onClick,
  onLoad,
  handleVideoClick,
  photoItems,
  name,
  profileImage,
  sharingText,
  sharingUrl
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
      handleVideoClick(photoItems, name, profileImage, sharingText, sharingUrl);
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <video
        className='lp-img'
        ref={videoRef}
        src={videoSrc}
        style={{ width: '100%', height: 'auto' }}
        onClick={handlePlayPause}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onLoadedData={onLoad}
        controls={false}
      />
      {!isPlaying && (
        <button
          onClick={handlePlayPause}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(0, 0, 0, 1)',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            fontSize: '20px',
            cursor: 'pointer'
          }}
        >
          <img src={'/assets/img/icons/play_btn.svg'} alt='Play' className='play-btn w-100' />
        </button>
      )}
    </>
  );
};

function Home() {

  const captainData = getItemFromLocalStorage('captainProfile');
  const token = captainData.captain_access_token;
  const businessListingId = getItemFromLocalStorage('listing_id');

  const [businessListing, setBusinessListing] = useState(null);
  const [listingPhotos, setListingPhotos] = useState([]);
  const [liveCrowd, setLiveCrowd] = useState([]);
  const [crowdPuller, setCrowdPuller] = useState([]);
  const [crowdPullerDetails, setCrowdPullerDetails] = useState([]);
  const [likeList, setLikeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCpPopupOpen, setIsCpPopupOpen] = useState(false);
  const [isCpullPopupOpen, setIsCpullPopupOpen] = useState(false);
  const [isFlPopupOpen, setIsFlPopupOpen] = useState(false);
  const [isLikeListModal, setIsLikeListModal] = useState(false);
  const [isPhotoDeleteModal, setIsPhotoDeleteModal] = useState(false);
  const [selectedListingName, setSelectedListingName] = useState('');
  const [photoDeleteId, setPhotoDeleteId] = useState('');
  const [isPhotoItemModal, setIsPhotoItemModal] = useState(false);
  const [isVideoItemModal, setIsVideoItemModal] = useState(false);
  const [popupImages, setPopupImages] = useState([]);
  const [photoOwnerImage, setPhotoOwnerImage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalListingPhotos, setTotalListingPhotos] = useState('');
  const [sharingText, setSharingText] = useState('');
  const [sharingUrl, setSharingUrl] = useState('');
  const [liveCrowdUserModal, setLiveCrowdUserModal] = useState(false);
  const [liveCrowdUserId, setLiveCrowdUserId] = useState('');

  const errorRef = useRef(null);
  const successMessageRef = useRef(null);
  
  /*const gridRef = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (imagesLoaded === listingPhotos.length && gridRef.current) {
      if (gridRef.current.updateLayout) {
        gridRef.current.updateLayout();
      } else {
        console.error("updateLayout method not found on gridRef.current");
      }
    }
  }, [imagesLoaded, listingPhotos]);

  const handleImageLoad = () => {
    setImagesLoaded(prevCount => prevCount + 1);
  };*/

  useEffect(() => {
    if ((error && errorRef.current) || (successMessage && successMessageRef.current)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error, successMessage]);

  const fetchListingPhotos = useCallback(async () => {
    if (businessListingId && token) {
      const formData = new FormData();
      formData.append('profile_id', businessListingId);
      formData.append('token', token);
      formData.append('profile_type', 'listing');
      setLoader(true);
      const url = `${params.baseURL}${params.endpoints.getListingPhotos}?page=${currentPage}`;
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: url,
          data: formData
        });
        if (response.data.items && Array.isArray(response.data.items)) {
          setListingPhotos(response.data.items);
          setTotalPages(response.data._meta.pageCount);
          setTotalListingPhotos(response.data._meta.totalCount);
        }
        else {
          setError('Listing not found');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoader(false);
      }
    }
  }, [businessListingId, token, currentPage]);

  useEffect(() => {
    const fetchBusinessListing = async () => {
      if (businessListingId && token) {
        const formData = new FormData();
        formData.append('id', businessListingId);
        formData.append('token', token);
        formData.append('utype', 'captain');
        setLoading(true);
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            url: `${params.baseURL}${params.endpoints.getPublicProfile}`,
            data: formData
          });
          if(response.data.status === 'found') {
            setBusinessListing(response.data);
            fetchListingPhotos();
          }
          else {
            setError('Listing not found');
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchBusinessListing();
  }, [businessListingId, token, fetchListingPhotos]);

  const fetchLiveCrowd = async () => {
    if (businessListingId && token) {
      const cData = new FormData();
      cData.append('listing_id', businessListingId);
      cData.append('token', token);
      cData.append('module_type', 'listing');
      cData.append('utype', 'captain');

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.getCheckenList}`,
          data: cData
        });
        if (response.data.items && Array.isArray(response.data.items)) {
          setLiveCrowd(response.data.items);
          setIsPopupOpen(true);
        }
        else if(response.data.status === 'error') {
          setError(response.data.list);
        }
        else {
          setError('Error fetching live crowd');
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const fetchCrowdPuller = async () => {
    if (token) {
      const cData = new FormData();
      cData.append('token', token);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.crowdPuller}`,
          data: cData
        });
        if (response.data.items && Array.isArray(response.data.items)) {
          setCrowdPuller(response.data.items);
          setIsCpPopupOpen(true);
        }
        else if(response.data.status === 'error') {
          setError(response.data.list);
        }
        else {
          setError('Error fetching Crowd Puller Data');
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const activateCrowdPuller = async (offerId) => {
    if (businessListingId && token) {
      const cData = new FormData();
      cData.append('token', token);
      cData.append('listing_id', businessListingId);
      cData.append('offer_id', offerId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.activateCrowdPuller}`,
          data: cData
        });
        if (response.data.status === 'ok') {
          setSuccessMessage(response.data.msg);
          setIsCpullPopupOpen(false)
        }
        else if(response.data.status === 'error') {
          setError(response.data.list);
          setIsCpullPopupOpen(false)
        }
        else {
          setError('Error Activating Offer.');
          setIsCpullPopupOpen(false)
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const fetchCrowdPullerDetails = async (offerId) => {
    await fetchCrowdPuller();
    setCrowdPullerDetails(crowdPuller.find(offer => offer.p_offer_id === offerId));
    setIsCpullPopupOpen(true);
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={`star ${i <= rating ? 'filled' : 'empty'}`}>
          &#9733;
        </span>
      );
    }
    return stars;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleImageClick = (photoItems, name, profileImage, sharingText, sharingUrl) => {
    setPopupImages(photoItems);
    setSelectedListingName(name);
    setPhotoOwnerImage(profileImage);
    setSharingText(sharingText);
    setSharingUrl(sharingUrl);
    setIsPhotoItemModal(true);
  };
  
  const closePhotoItemModal = () => {
    setIsPhotoItemModal(false);
    setPopupImages([]);
  };

  const handleVideoClick = (photoItems, name, profileImage, sharingText, sharingUrl) => {
    setPopupImages(photoItems);
    setSelectedListingName(name);
    setPhotoOwnerImage(profileImage);
    setSharingText(sharingText);
    setSharingUrl(sharingUrl);
    setIsVideoItemModal(true);
  };

  const closeVideoItemModal = () => {
    setIsVideoItemModal(false);
    setPopupImages([]);
  };

  const handleFollowList = async () => {
    setIsFlPopupOpen(true);
  };

  const openDeleteModal = async (photoId, listingName) => {
    setPhotoDeleteId(photoId);
    setSelectedListingName(listingName);
    setIsPhotoDeleteModal(true);
  };

  const handlePhotoDelete = async () => {
    if (token) {
      const cData = new FormData();
      cData.append('token', token);
      cData.append('photo_id', photoDeleteId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.deletePhoto}`,
          data: cData
        });
        if (response.data.status === 'completed') {
          setSuccessMessage(response.data.msg);
          setIsPhotoDeleteModal(false)
          fetchListingPhotos();
        }
        else if(response.data.status === 'error') {
          setError(response.data.list);
          setIsPhotoDeleteModal(false)
        }
        else {
          setError('Error Deleting Photo.');
          setIsPhotoDeleteModal(false)
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const openLikeListModal = async (photoId, photoItemId ,listingName) => {
    setSelectedListingName(listingName);
    setIsLikeListModal(true);

    if (token) {
      setLoading(true);
      const formData = new FormData();
      formData.append('token', token);
      formData.append('photo_id', photoId);
      formData.append('photo_item_id', photoItemId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.likeList}`,
          data: formData
        });
        if (response.data.items && Array.isArray(response.data.items)) {
          setLikeList(response.data.items);
          setIsLikeListModal(true);
        }
        else {
          setError('Like List not found');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleLiveCrowdUser = (id) => {
    setLiveCrowdUserModal(true);
    setLiveCrowdUserId(id);
  };

  const closeLiveCrowdUser = () => {
    setLiveCrowdUserModal(false);
  }

  if (!captainData) {
    return <p>No profile data found.</p>;
  }

  return (
    <div>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Claim your pre-existing Location Gallery at FS curated specifically for PBCL (Pubs, Bars, Cafes, Lounges) & Restaurant Industry to leverage The Most Organized Virtual Marketplace - Wire." />
      </Helmet>

      {successMessage && <div className="alert alert-success mb-4" ref={successMessageRef}>{successMessage}</div>}
      {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 

      {loading ? (
          <Loading />
      ) : (
        <div>
          {businessListing ? (
            <div className='bldv-section'>
              <div className='bldv-img'>
                <div className='blimgbox'>
                  <div style={{position:'relative'}}>
                    <img className='bl-img' 
                      src={(businessListing.cafe_profile_data.business_profile_logo_url ? 
                        businessListing.cafe_profile_data.business_profile_logo_url : 
                        '/assets/img/default_logo.svg')} 
                        alt={businessListing.cafe_profile_data.business_name} 
                    />
                    <img className='bl-shield' src={'/assets/img/fs-verified-shield.svg'} alt='Fyre Search' />
                  </div>
                  <div className='bl-rating'>
                    <span>{renderStars(businessListing.cafe_profile_data.business_rating)}</span>
                  </div>
                </div>
                <h4>{businessListing.cafe_profile_data.business_name}</h4>
                <div className='bl-cuisines'>
                {businessListing.cafe_profile_data.cuisines && businessListing.cafe_profile_data.cuisines.length > 0 ? (
                  businessListing.cafe_profile_data.cuisines.map((cuisine) => (
                    <span key={cuisine.cuisine_id}>{cuisine.cuisine_name}</span>
                  ))
                ) : (
                  null
                )}
                </div>
              </div>
              <span className='bl-line'></span>
              <div className='lcbs'>
                <button className='lc-btn' onClick={fetchLiveCrowd}>Live Crowrd</button>
              </div>
              <span className='bl-line'></span>
              <div className='lcbs'>
                <button className='lc-btn' onClick={fetchCrowdPuller}>Crowd Puller</button>
              </div>
              <span className='bl-line'></span>
              <div className='frfbox'>
                <div onClick={handleFollowList}>
                  <p className='frf-title'>Requests</p>
                  <span className='frf-number' style={{color:'red'}}>{businessListing.pending_requests}</span>
                </div>
                <div onClick={handleFollowList}>
                  <p className='frf-title'>Followers</p>
                  <span className='frf-number'>{businessListing.followers}</span>
                </div>
                <div onClick={handleFollowList}>
                  <p className='frf-title'>Footfall</p>
                  <span className='frf-number'>{businessListing.footfall}</span>
                </div>
              </div>
              <span className='bl-line'></span>
              <div className='frfbox'>           
                <Link to="/listing/menu">
                  <img className='mto-img' src={'/assets/img/icons/fs_side_menu.svg'} alt='Fyre Search' />
                  <p className='mto-title'>Menu</p>
                </Link>
                <Link to="/listing/tables">
                  <img className='mto-img' src={'/assets/img/icons/fs_side_table.svg'} alt='Fyre Search' />
                  <p className='mto-title'>Tables</p>
                </Link>
                <Link to="/listing/offers">
                  <img className='mto-img' src={'/assets/img/icons/fs_side_offer.svg'} alt='Fyre Search' />
                  <p className='mto-title'>Offers</p>
                </Link>
              </div>
              <span className='bl-line'></span>
              <div className='lpbs'>
                {loader ? (
                  <Loading />
                ) : listingPhotos.length > 0 ? (
                  <>
                    <h4 className='lp-title'>Photos ({totalListingPhotos})</h4>
                    <StackGrid
                      className={'photo_grid_view'}
                      columnWidth={300}
                      gutterWidth={16}
                      gutterHeight={16}
                      monitorImagesLoaded={true}
                      appear={scaleDown.appear}
                      appeared={scaleDown.appeared}
                      enter={scaleDown.enter}
                      entered={scaleDown.entered}
                      leaved={scaleDown.leaved}
                      //ref={gridRef}
                    >
                      {listingPhotos.map(item => (
                        <div className='lpib' key={item.photo_id}>
                          <div className='lpib-action-overlay'>
                            {item.received_from_type === "customer" ? (
                              <button className='lpib-edit-btn'><i className="fas fa-bookmark"></i></button>
                            ) : (
                              <Link className='lpib-edit-btn' to={`/listing/edit-post/${item.photo_id}`}>
                                <img src={'/assets/img/icons/fs_edit.svg'} width={'18px'} alt='' />
                              </Link>
                            )}
                            <button className='lpib-edit-btn' onClick={() => openDeleteModal(item.photo_id, item.owner_info.title)}>
                              <img src={'/assets/img/icons/fs_post_delete.svg'} width={'18px'} alt='' />
                            </button>
                            <button className='lpib-edit-btn' onClick={() => openLikeListModal(item.photo_id, item.photo_item_id, item.owner_info.title)}>
                              <img src={'/assets/img/icons/fs_heart_like.svg'} width={'18px'} alt='' />
                            </button>
                          </div>
                          {item.media_type === "P" ? (
                            <img
                              className='lp-img'
                              src={item.photo_image_url}
                              alt={item.photo_caption}
                              onClick={() => handleImageClick(
                                item.photo_items, 
                                item.owner_info.title, 
                                item.owner_info.image,
                                item.sharing_text,
                                item.sharing_url
                              )} 
                              //onLoad={handleImageLoad}
                            />
                          ) : item.media_type === "V" ? (
                            <VideoWithCustomPlayButton
                              videoSrc={item.photo_image_url}
                              //onLoad={handleImageLoad}
                              handleVideoClick={handleVideoClick}
                              photoItems={item.photo_items}
                              name={item.owner_info.title}
                              profileImage={item.owner_info.image}
                              sharingText={item.sharing_text}
                              sharingUrl={item.sharing_url}
                            />
                          ) : null}
                          <div className='lpib-overlay'>
                            <div className='lpib-overlay-img'>
                              <img src={item.owner_info.image} alt={item.owner_info.title} />
                            </div>
                            <div className='lpib-overlay-text'>
                              <p>{item.created_time_as_text}</p>
                              <p>{item.owner_info.title}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </StackGrid>
                    {totalPages > 1 && (
                      <div className="pagination">
                          <button 
                              onClick={() => handlePageChange(1)}
                              className={currentPage === 1 ? 'page-btn disabled' : 'page-btn'}
                              disabled={currentPage === 1}
                          >
                              First
                          </button>
                          {Array.from({ length: totalPages }, (_, index) => (
                              <button 
                                  key={index + 1}
                                  onClick={() => handlePageChange(index + 1)}
                                  className={currentPage === index + 1 ? 'page-btn active' : 'page-btn'}
                              >
                                  {index + 1}
                              </button>
                          ))}
                          <button 
                              onClick={() => handlePageChange(totalPages)}
                              className={currentPage === totalPages ? 'page-btn disabled' : 'page-btn'}
                              disabled={currentPage === totalPages}
                          >
                              Last
                          </button>
                      </div>
                  )}
                  </>
                ) : (
                  <div>
                    <h4 className='lp-title'>Photos ({totalListingPhotos})</h4>
                    <div className='empty-section'>
                      <img src={'assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                      <p>No Tagged Photos</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className='profile-section'>
              <div className='profile-box'>
                <div className='profile-image-box'>
                  <img src={captainData.captain_logo} alt={captainData.name} className='profile-img' />
                </div>
                <div className='profile-info-box'>
                  <h4 className='business-name'>{captainData.business_name}</h4>
                  <p className='business-info'>{captainData.business_info}</p>
                </div>
              </div>
              <div className='row profile-footer'>
                <div className='col-6'>
                  <Link className="pf-btn" to="/my-profile">
                    <span>Edit Profile</span>
                  </Link>
                </div>
                <div className='col-6'>            
                  <Link className="pf-btn" to="/add-listing">
                    <span>Add Your Business</span>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isPopupOpen && (
        <div className='popup'>
          <div className='popup-content'style={{width:'100%', margin: '30px', position: 'relative'}}>
            {liveCrowd.length > 0 ? (
              <>
                <div className='tcp-header'>
                  <div style={{width:'400px'}}>
                    <img src={'/assets/img/icons/live_crowd_logo.svg'} style={{width:'100%'}} alt='' />
                  </div>
                  <span className='popup-close' onClick={() => setIsPopupOpen(false)}>
                    <img src={'/assets/img/icons/close_icon.svg'} style={{width:'100%'}} alt='' />
                  </span>
                </div>
                <div className='lcdbox'>
                  {liveCrowd.map(person => (
                    <div className='col-lcd' style={{cursor:'pointer'}} key={person.checkin_id}>
                      <div className='lcdubox' onClick={() => handleLiveCrowdUser(person.owner_info.id)}>
                        <div className='lcduimg' >
                          <img className='lcu-img' src={person.owner_info.image} alt='Live Crowd' />
                        </div>
                        <div className='lcdutxt'>
                          <span>{person.owner_info.title}</span>
                          <span>{renderStars(person.customer_rating)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div>
                <div className='tcp-header'>
                  <div style={{width:'400px'}}>
                    <img src={'/assets/img/icons/live_crowd_logo.svg'} style={{width:'100%'}} alt='' />
                  </div>
                  <span className='popup-close' onClick={() => setIsPopupOpen(false)}>
                    <img src={'/assets/img/icons/close_icon.svg'} style={{width:'100%'}} alt='' />
                  </span>
                </div>
                <div className='empty-section'>
                  <img src={'/assets/img/icons/no_live_crowd.svg'} style={{width:'250px'}} alt='Fyre Search' />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {isCpPopupOpen && (
        <div className='popup cp-background'>
          <div className='popup-content'style={{width:'100%', margin: '30px', position: 'relative'}}>
            {crowdPuller.length > 0 ? (
              <>
                <div className='tcp-header'>
                  <div style={{width:'400px'}}>
                    <img src={'/assets/img/tcp_logo.svg'} style={{width:'100%'}} alt='' />
                  </div>
                  <span className='popup-close' onClick={() => setIsCpPopupOpen(false)}>
                    <img src={'/assets/img/icons/close_icon.svg'} style={{width:'100%'}} alt='' />
                  </span>
                </div>
                <div className='row cposb'>
                  {crowdPuller.map(offers => (
                    <div className='col-3 cpolb' key={offers.p_offer_id}>
                      <div className='cpolmb' onClick={() => fetchCrowdPullerDetails(offers.p_offer_id)}>
                        <img src={`/assets/img/icons/cp_offer_${parseFloat(offers.discount_value)}.svg`} alt='' />
                        <button className='lcuc-title' onClick={() => fetchCrowdPullerDetails(offers.p_offer_id)}>{offers.offer_title}</button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='row cpotxtbox'>
                  <div className='col-12'>
                    <p className='cpo-txt'>Crowd Puller, The AI-Based Advanced Feature is here to help you achieve desirable customer acquisition rates for your outlet. 
                      Below are a few strategically planned programs. 
                      Choosing any one of them will automatically activate the offer on your outlet and send push notification to the AI suggested potential customers. 
                      So, Choose wisly! Read Terms & Conditons.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className='lcp-header'>
                  <div>
                    <h4>Crowd Puller</h4>
                  </div>
                  <span className='popup-close' onClick={() => setIsCpPopupOpen(false)}>&times;</span>
                </div>
                <div className='empty-section'>
                  <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                  <p>No Offers Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {isCpullPopupOpen && crowdPullerDetails &&(
        <div className='popup'>
          <div className='popup-content'>
              <div className='cpo-header' style={{margin:'0'}}>
                <div>
                  <h4>Crowd Puller</h4>
                </div>
                <span className='popup-close' onClick={() => setIsCpullPopupOpen(false)}>&times;</span>
              </div>
              <div className='row cposb'>
                <div className='col-12'>
                  <div className='cpods'>
                    <img src={`/assets/img/icons/cp_detail_${parseFloat(crowdPullerDetails.discount_value)}.svg`} alt='' />
                  </div>
                </div>
                <div className='col-12'>
                  <div className='cpods-details'>
                    <button class="cpa-btn" onClick={() => activateCrowdPuller(crowdPullerDetails.p_offer_id)}>
                      <strong>Activate Offer</strong>
                      <div id="container-stars">
                        <div id="stars"></div>
                      </div>

                      <div id="glow">
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                    </button>
                    <span className='side-line'></span>
                    <h4 className='cpotct'>Terms And Conditions</h4>
                    <div className='cpods-terms' dangerouslySetInnerHTML={{ __html: crowdPullerDetails.offer_tnc }} />
                  </div>
                </div>
              </div>
          </div>
        </div>
      )}

      {isPhotoDeleteModal && photoDeleteId &&(
        <div className='modal-overlay'>
          <div className='modal-content'>
            <div className='modal-body'>
              <h6>Delete</h6>
              <p>Would you like to delete photo @ {selectedListingName} ?</p>
            </div>
            <div className='modal-footer'>
                <button className="close-button" onClick={() => setIsPhotoDeleteModal(false)} style={{marginRight:'4rem'}}>No</button>
                <button className="close-button" onClick={handlePhotoDelete}>Yes</button>
            </div>
          </div>
        </div>
      )}

      {isLikeListModal && (
        <div className='popup'>
          <div className='popup-content'>
            {likeList.length > 0 ? (
              <>
                <div className='lcp-header'>
                  <div>
                    <h4>Likes</h4>
                    <p>for {selectedListingName}</p>
                  </div>
                  <span className='popup-close' onClick={() => setIsLikeListModal(false)}>&times;</span>
                </div>
                <div>
                  {likeList.map(list => (
                    <div className='lculb' key={list.photo_like_id}>
                      <div className='lcirts'>
                        <div className='lcuib'>
                          <img className='lcllu-img' src={list.owner_info.image} alt='Live Crowd' />
                        </div>
                        <div className='lcucb'>
                          <p className='lcuc-title'>{list.owner_info.title}</p>
                          <p className='lcuc-time'>{list.created_time_as_text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div>
                <div className='lcp-header'>
                  <div>
                    <h4>Likes</h4>
                    <p>for {selectedListingName}</p>
                  </div>
                  <span className='popup-close' onClick={() => setIsLikeListModal(false)}>&times;</span>
                </div>
                <div className='empty-section'>
                  <p>No Likes Receives Yet!</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <FollowersListPopup show={isFlPopupOpen} onClose={() => setIsFlPopupOpen(false)} />

      {liveCrowdUserModal && 
        <LiveCrowdUserDetails
          itemId={liveCrowdUserId}
          onClose={closeLiveCrowdUser}
        />
      }

      {isPhotoItemModal && 
        <PhotoItemsPopup 
          images={popupImages} 
          businessName={selectedListingName} 
          profileImage={photoOwnerImage}
          sharingText={sharingText}
          sharingUrl={sharingUrl}
          onClose={closePhotoItemModal}
        />
      }

      {isVideoItemModal && 
        <VideoItemsPopup 
          images={popupImages} 
          businessName={selectedListingName} 
          profileImage={photoOwnerImage}
          sharingText={sharingText}
          sharingUrl={sharingUrl}
          onClose={closeVideoItemModal}
        />
      }

    </div>
  );
}

export default Home;
