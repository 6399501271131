import React from 'react';
import { Link } from 'react-router-dom';
import { useSubscription } from '../SubscriptionContext';
import Loading from './Loading';

const SubscriptionCheck = ({ children }) => {
    const subscription = useSubscription();

    if (!subscription) {
        return <div><Loading /></div>;
    }

    if (subscription.status === 'error') {
        if (subscription.upgrade_title && subscription.upgrade_description) {
            return (
                <div className='text-center'>
                    <img src={'/assets/img/icons/error_black.svg'} alt='' style={{ width: '200px', marginBottom: '2rem' }} />
                    <h4 className='text-uppercase mb-2'>{subscription.upgrade_title}</h4>
                    <p>{subscription.upgrade_description}</p>
                    <Link to='/plans' className='btn btn-dark w-100 text-uppercase mb-4'>Upgrade</Link>
                    <Link to='/home' className='btn btn-secondary w-100 text-uppercase'>Back to home</Link>
                </div>
            );
        }
        else if (subscription.list && subscription.list.length > 0) {
            return <div className='text-center'>
                <img src={'/assets/img/icons/error_black.svg'} alt='' style={{ width: '200px', marginBottom: '2rem' }} />
                <h4 className='text-uppercase mb-2'>{subscription.list[0]}</h4>
                <Link to='/plans' className='btn btn-dark w-100 text-uppercase mb-4'>Upgrade</Link>
                <Link to='/home' className='btn btn-secondary w-100 text-uppercase'>Back to home</Link>
            </div>;
        } else {
            return <div className='text-center'>
                <img src={'/assets/img/icons/error_black.svg'} alt='' style={{ width: '200px', marginBottom: '2rem' }} />
                <h4 className='text-uppercase mb-2'>Error</h4>
                <p>An unexpected error occurred.</p>
                <Link to='/home' className='btn btn-secondary w-100 text-uppercase'>Back to home</Link>
            </div>
        }
    }

    return <>{children}</>;
};

export default SubscriptionCheck;
